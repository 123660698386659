import React, { FC, useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";

import {
  GET_TIMETABLES,
  UPDATE_TIMETABLE,
  DELETE_TIMETABLE,
  CREATE_TIMETABLE,
} from "../graphQL/timetable";

interface IProps {
  timetables: Timetable[];
  loading: boolean;
  createTimetable: (newTimetableData: NewTimetableData) => void;
  updateTimetable: (
    newTimetableData: NewTimetableData,
    timetableId: number
  ) => void;
  deleteTimetable: (id: number) => void;
}

const TimetablesContext = React.createContext<IProps>({} as IProps);

const TimetableProvider: FC = (props) => {
  const [timetables, setTimetables] = useState<Timetable[]>([]);
  const { data, loading } = useQuery(GET_TIMETABLES, {
    fetchPolicy: "no-cache",
  });
  const [createTimetableGql] = useMutation(CREATE_TIMETABLE);
  const [updateTimetableGql] = useMutation(UPDATE_TIMETABLE);
  const [deleteTimetableGql] = useMutation(DELETE_TIMETABLE);

  useEffect(() => {
    if (data) setTimetables(data.listTimetablefull);
  }, [data]);

  const createTimetable = (newTimetableData: NewTimetableData) => {
    const payload = createTimetablePayload(newTimetableData);

    createTimetableGql({ variables: { input: payload } })
      .then(({ data }) => {
        const newTimetable = {
          ...newTimetableData,
          id: data.createTimetable.id,
        };

        setTimetables([...timetables, newTimetable]);
      })
      .catch((error) => {
        throw error;
      });
  };

  const updateTimetable = (
    newTimetableData: NewTimetableData,
    timetableId: number
  ) => {
    const payload = createTimetablePayload(newTimetableData);

    if (payload && timetableId) {
      const updatedTimetable = {
        id: timetableId,
        ...payload,
      };

      updateTimetableGql({ variables: { input: updatedTimetable } })
        .then(() => {
          const timetable = {
            id: timetableId,
            ...newTimetableData,
          };
          const timetableData = [...timetables];
          const timetableIndex = timetables.findIndex(
            (timetable) => timetable.id === timetableId
          );

          if (timetableIndex > -1) {
            timetableData.splice(timetableIndex, 1, timetable);

            setTimetables(timetableData);
          }
        })
        .catch((error) => {
          throw error;
        });
    }
  };

  const deleteTimetable = (id: number) => {
    deleteTimetableGql({ variables: { input: { id } } })
      .then(() => {
        const newTimetableList = timetables.filter(
          ({ id: timetableId }) => timetableId !== id
        );

        setTimetables(newTimetableList);
      })
      .catch((error) => {
        throw error;
      });
  };

  const createTimetablePayload = (newTimetableData: NewTimetableData) => {
    const {
      car: { id: car_id },
      driver: { id: driver_id },
      operator: { id: operator_id },
      route: { id: route_id },
      project: { id: project_id },
      from_time,
      to_time,
    } = newTimetableData;

    if (driver_id && operator_id) {
      return {
        from_time: new Date(from_time).getTime(),
        to_time: new Date(to_time).getTime(),
        route_id,
        driver_id,
        operator_id,
        project_id,
        car_id,
      };
    }
  };

  const value = {
    timetables,
    loading,
    createTimetable,
    updateTimetable,
    deleteTimetable,
  };

  return <TimetablesContext.Provider value={value} {...props} />;
};

const useTimetable = () => {
  const context = React.useContext(TimetablesContext);

  if (context === undefined) {
    throw new Error(`useTimetable must be used within a TimetableProvider`);
  }

  return context;
};

export { TimetableProvider, useTimetable };
