import React, { useEffect } from "react";
import { Form, Input, Modal } from "antd";
import { formLayout } from "../../../constants/layout";

interface IProps {
  visible: boolean;
  user?: Driver | Operator;
  onClose: () => void;
  onSave: (user: Driver | Operator) => void;
}

export const ModalModification = ({
  visible,
  user,
  onClose,
  onSave,
}: IProps) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (user) {
      const { name, surname, telephone } = user;

      form.setFieldsValue({ name, surname, telephone });
    }
  }, [user]);

  const closeModal = () => {
    form.resetFields();
    onClose();
  };

  const saveUser = () => {
    form.validateFields().then((value) => {
      const id = user?.id;

      const payload: Driver | Operator = {
        ...(id && { id }),
        ...value,
      };

      onSave(payload);
      closeModal();
    });
  };

  return (
    <Modal
      title={"Zarządzanie"}
      visible={visible}
      onCancel={closeModal}
      cancelText={"Anuluj"}
      maskClosable={false}
      width={600}
      okText={"Zapisz"}
      onOk={saveUser}
    >
      <Form form={form} {...formLayout}>
        <Form.Item
          label={"Nazwisko:"}
          name={"surname"}
          rules={[{ required: true, message: "Podaj nazwisko" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={"Imię:"}
          name={"name"}
          rules={[{ required: true, message: "Podaj imię" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={"Numer telefonu: "}
          name={"telephone"}
          rules={[{ required: true, message: "Podaj numer telefonu" }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
