import React from "react";

import { ColumnsType } from "antd/es/table";
import { Button, Popconfirm } from "antd";
import { compare } from "natural-orderby";

import * as Styled from "../../assets/style/Table.styles";

interface IProps {
  onDeleteCar: (id: string) => void;
  onEditCar: (id: string) => void;
}

const Columns = ({ onDeleteCar, onEditCar }: IProps): ColumnsType<Car> => [
  {
    title: "Identyfikator samochodu",
    dataIndex: "id",
    key: "id",
    filterMultiple: false,
    sorter: (a, b) => compare()(a.id, b.id),
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "Nazwa samochodu",
    dataIndex: "car_name",
    key: "car_name",
    filterMultiple: false,
    sorter: (a, b) => compare()(a.car_name, b.car_name),
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "",
    dataIndex: "edit",
    width: 60,
    key: "edit",
    render: (_, { id }) => (
      <Button
        data-testid="edit-car-button"
        type={"link"}
        icon={<Styled.Edit />}
        onClick={() => onEditCar(id)}
      />
    ),
  },
  {
    title: "",
    dataIndex: "delete",
    width: 60,
    key: "delete",
    render: (_, { id }) => (
      <Popconfirm
        title="Na pewno usunąć?"
        onConfirm={() => onDeleteCar(id)}
        okText="Usuń"
        cancelText="Anuluj"
        placement={"topRight"}
      >
        <Button
          data-testid="delete-car-button"
          type={"link"}
          icon={<Styled.Trash />}
        />
      </Popconfirm>
    ),
  },
];

export default Columns;
