import React from "react";

export const filterItem = (item, searchValue) => {
  return Object.keys(item).some((key) => {
    if (typeof item[key] === "number") {
      return (
        item[key]
          .toString()
          .toLowerCase()
          .indexOf(searchValue.toLowerCase()) !== -1
      );
    } else if (typeof item[key] === "string") {
      return item[key].toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
    }
  });
};
