import styled from "styled-components";
import { Button as AntButton } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

export const Button = styled(AntButton)`
  height: 32px;
  font-weight: 600;
  margin-left: 10px;
  background: #ffffff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  border-color: transparent;

  :hover {
    color: #383838;
    border-color: transparent;
  }
`;

export const PlusIcon = styled(PlusCircleOutlined)`
  font-size: 15px;
`;
