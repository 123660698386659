import React from "react";
import * as Styled from "./columns.styles";
import { ColumnsType } from "antd/es/table";
import { Button, Popconfirm } from "antd";
import { compare } from "natural-orderby";

interface IProps {
  editDriver: (driverId: number) => void;
  deleteDriver: (driverId: number) => void;
}

const Columns = ({
  editDriver,
  deleteDriver,
}: IProps): ColumnsType<DriverTable> => [
  {
    title: "Nazwisko i Imię",
    dataIndex: "surname_name",
    key: "surname_name",
    filterMultiple: false,
    sorter: (a, b) => compare()(a.surname_name, b.surname_name),
    sortDirections: ["descend", "ascend"],
  },

  {
    title: "Numer telefonu",
    dataIndex: "telephone",
    key: "telephone",
    filterMultiple: false,
    sorter: (a, b) => compare()(a.telephone, b.telephone),
    sortDirections: ["descend", "ascend"],
  },

  {
    title: "",
    dataIndex: "edit",
    width: 60,
    key: "edit",
    render: (_, { id }) => (
      <Button
        type={"link"}
        icon={<Styled.EditIcon />}
        onClick={() => editDriver(id)}
      />
    ),
  },

  {
    title: "",
    dataIndex: "delete",
    width: 60,
    key: "delete",
    render: (_, { id }) => (
      <Popconfirm
        title="Czy napewno usunąć?"
        onConfirm={() => deleteDriver(id)}
        okText="Usuń"
        cancelText="Anuluj"
        placement={"topRight"}
      >
        <Button type={"link"} icon={<Styled.TrashIcon />} />
      </Popconfirm>
    ),
  },
];

export default Columns;
