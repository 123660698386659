import React, { useState } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import MapComponent from "../common/Map/MapComponent";
import MeasuringSetsView from "../MeasuringSetsView/MeasuringSetsView";
import { useRoutes } from "../../context/routesContext";
import { useMeasurements } from "../../context/measurementsContext";
import * as Styled from "./Home.styles";

const Home = () => {
  const [selectedCoordinates, setSelectedCoordinates] = useState<number[]>();
  const { routes } = useRoutes();
  const { measurements } = useMeasurements();

  const mappedMeasurements = measurements.filter(
    (item) => item.route && item.driver
  ) as ParsedMeasurement[];

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const onCenterPoint = (coordinates: number[]) =>
    setSelectedCoordinates(coordinates);

  return (
    <Styled.Container>
      <Styled.MapContainer>
        {routes.length ? (
          <MapComponent
            routes={routes}
            measurements={measurements}
            selectedCoordinates={selectedCoordinates}
          />
        ) : (
          <Styled.SpinnerContainer>
            <Spin indicator={antIcon} size={"large"} />
          </Styled.SpinnerContainer>
        )}
      </Styled.MapContainer>

      <Styled.MeasurementContainer>
        <MeasuringSetsView
          measurements={mappedMeasurements}
          onSelectedMeasurement={onCenterPoint}
        />
      </Styled.MeasurementContainer>
    </Styled.Container>
  );
};

export default Home;
