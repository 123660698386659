import React from "react";
import { ColumnsType } from "antd/es/table";
import { compare } from "natural-orderby";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

import * as Styled from "./MeasuringPackages.styles";

const Columns = (
  acceptPackage: (item: MeasuringPackage) => void,
  denyPackage: (item: MeasuringPackage) => void
): ColumnsType<MeasuringPackage> => [
  {
    title: "Nazwa urządzenia pomiarowego",
    dataIndex: "UUID_row",
    key: "UUID_row",
    filterMultiple: false,
    showSorterTooltip: false,
    sorter: (a, b) => compare()(a.UUID_row, b.UUID_row),
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "Nazwa paczki",
    dataIndex: "package_name",
    key: "package_name",
    filterMultiple: false,
    showSorterTooltip: false,
    sorter: (a, b) => compare()(a.package_name, b.package_name),
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "Data rozpoczęcia pomiaru",
    dataIndex: "date_from",
    key: "date_from",
    filterMultiple: false,
    showSorterTooltip: false,
    sorter: (a, b) => compare()(a.date_from, b.date_from),
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "Data zakończenia pomiaru",
    dataIndex: "date_to",
    key: "date_to",
    filterMultiple: false,
    showSorterTooltip: false,
    sorter: (a, b) => compare()(a.date_to, b.date_to),
    sortDirections: ["descend", "ascend"],
  },
  {
    render: (_, record) => (
      <Styled.ButtonsContainer>
        <Styled.Button
          type="primary"
          icon={<CheckOutlined />}
          ghost={true}
          onClick={() => acceptPackage(record)}
        />
        <Styled.Button
          type="primary"
          danger
          icon={<CloseOutlined />}
          ghost={true}
          onClick={() => denyPackage(record)}
        />
      </Styled.ButtonsContainer>
    ),
  },
];

export default Columns;
