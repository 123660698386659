import { gql } from "@apollo/client";

const GET_ROUTES = gql`
  query QueryRoutes {
    listRoutes {
      common_name
      id
      layer_color
      layer_name
      shp_filename
    }
  }
`;

const GET_FEATURE_COLLECTION = gql`
  query QueryRouteGeometry($layer_name: String!, $shp_filename: String!) {
    callLambdaReadFCfromSHP(
      layer_name: $layer_name
      shp_filename: $shp_filename
    )
  }
`;

const CREATE_ROUTE = gql`
  mutation CreateRoute($input: CreateRouteInput!) {
    createRoute(input: $input) {
      shp_filename
      layer_name
      layer_color
      id
      common_name
    }
  }
`;

const UPDATE_ROUTE = gql`
  mutation UpdateRoute($input: UpdateRouteInput!) {
    updateRoute(input: $input) {
      shp_filename
      layer_name
      layer_color
      id
      common_name
    }
  }
`;

const DELETE_ROUTE = gql`
  mutation DeleteRoute($input: DeleteRouteInput!) {
    deleteRoute(input: $input) {
      shp_filename
      layer_name
      layer_color
      id
      common_name
    }
  }
`;

export {
  GET_ROUTES,
  GET_FEATURE_COLLECTION,
  CREATE_ROUTE,
  UPDATE_ROUTE,
  DELETE_ROUTE,
};
