import { gql } from "@apollo/client";

const PHOTO_SUBSCRIPTION = gql`
  subscription PhotoSubscription {
    onCreateImage {
      UUID_row
      bucket
      camera
      path
    }
  }
`;

export { PHOTO_SUBSCRIPTION };
