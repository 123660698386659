import React, { useState } from "react";
import {
  UserOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
  LockOutlined,
} from "@ant-design/icons";

import * as Styled from "./Login.styles";
import { useAuth } from "../../hooks/useAuth";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { isLoading, signIn } = useAuth();

  const onSubmit = () => signIn({ username, password });

  return (
    <Styled.Container>
      <Styled.LoginCard>
        <Styled.Form data-testid="form" onFinish={onSubmit}>
          <Styled.Input
            data-testid="login"
            title="username"
            size="large"
            placeholder="Login"
            prefix={<UserOutlined />}
            value={username}
            onChange={({ target }) => setUsername(target.value)}
          />
          <Styled.Input.Password
            data-testid="password"
            role="password"
            title="password"
            size="large"
            prefix={<LockOutlined />}
            placeholder="Hasło"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            value={password}
            onChange={({ target }) => setPassword(target.value)}
          />
          <Styled.Button
            data-testid="submit"
            htmlType="submit"
            type="primary"
            size="large"
            loading={isLoading}
          >
            Zaloguj
          </Styled.Button>
        </Styled.Form>
      </Styled.LoginCard>
    </Styled.Container>
  );
};

export default Login;
