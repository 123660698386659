import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { AuthOptions, createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import appSyncConfig from "../aws-exports";

export const useClient = () => {
  const url = appSyncConfig.aws_appsync_graphqlEndpoint;
  const region = appSyncConfig.aws_appsync_region;
  const auth: AuthOptions = {
    type: "API_KEY",
    apiKey: appSyncConfig.aws_appsync_apiKey,
  };

  const link = ApolloLink.from([
    createAuthLink({ url, region, auth }),
    createSubscriptionHandshakeLink({ url, region, auth }),
  ]);

  return new ApolloClient({
    link,
    cache: new InMemoryCache({
      addTypename: false,
    }),
  });
};
