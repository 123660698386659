import styled from "styled-components";
import edit_icon from "../../../assets/icons/edit_icon.svg";
import trash_icon from "../../../assets/icons/trash_icon.svg";

export const EditIcon = styled.div`
  background-image: url(${edit_icon});
  height: 19px;
  width: 19px;
`;

export const TrashIcon = styled.div`
  background-image: url(${trash_icon});
  height: 18px;
  width: 14px;
`;
