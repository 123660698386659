import React, { useEffect } from "react";
import { Form, Input, Modal } from "antd";
import { formLayout } from "../../../constants/layout";
import { useCars } from "../../../context/carsContext";

interface IProps {
  visible: boolean;
  editedCar?: Car;
  onClose: () => void;
  onSaveCar: (car: Car) => void;
}

const CarManagementModal = ({
  visible,
  editedCar,
  onClose,
  onSaveCar,
}: IProps) => {
  const { cars } = useCars();
  const [form] = Form.useForm();

  useEffect(() => {
    if (editedCar) {
      const { id, car_name } = editedCar;

      form.setFieldsValue({ id, car_name });
    }
  }, [editedCar]);

  const closeModal = () => {
    form.resetFields();
    onClose();
  };

  const saveCar = () => {
    form.validateFields().then((value) => {
      onSaveCar(value);
      closeModal();
    });
  };

  const checkCarId = () => {
    const id = form.getFieldValue("id");

    if (id) {
      const result = cars.find(
        ({ id: carId }) => carId.toLowerCase() === id.toLowerCase()
      );
      if (!result) return Promise.resolve();

      return Promise.reject(
        new Error("Podany identyfikator samochodu jest już wykorzystywany")
      );
    } else return Promise.resolve();
  };

  return (
    <Modal
      title={"Zarządzanie samochodem"}
      visible={visible}
      onCancel={closeModal}
      cancelText={"Anuluj"}
      maskClosable={false}
      width={850}
      okText={"Zapisz"}
      onOk={saveCar}
    >
      <Form form={form} {...formLayout}>
        <Form.Item
          label={"Identyfikator samochodu:"}
          name={"id"}
          rules={[
            {
              required: true,
              message: "Podaj identyfikator samochodu",
            },
            { validator: checkCarId },
          ]}
        >
          <Input disabled={!!editedCar} />
        </Form.Item>
        <Form.Item
          label={"Nazwa samochodu:"}
          name={"car_name"}
          rules={[
            {
              required: true,
              message: "Podaj nazwę samochodu",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CarManagementModal;
