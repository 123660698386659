import React, { useMemo, useState } from "react";
import * as Styled from "./OperatorsManagement.styles";
import { useOperatorManagement } from "../../context/operatorsContext";
import { useTimetable } from "../../context/timetablesContext";
import { openNotificationWithIcon } from "../common/Notification/Notification";
import { ModalModification } from "../common/ModalModification/ModalModification";
import Columns from "./columns";

import { Table } from "../../assets/style/Table.styles";
import { Button, PlusIcon } from "../../assets/style/AddButton.styles";
import { Header, SearchInput } from "../../assets/style/SearchInput.styles";
import { filterItem } from "../../helpers/filterTable";
import { SearchOutlined } from "@ant-design/icons";

const OperatorsManagement = () => {
  const {
    loading,
    dataSourceOperators,
    removeOperator,
    modifyOperator,
    createOperator,
  } = useOperatorManagement();

  const [visibleModal, setVisibleModal] = useState(false);
  const [editedOperator, setEditedOperator] = useState<Operator>();
  const [searchValue, setSearchValue] = useState("");

  const { timetables } = useTimetable();

  const editOperator = (operatorId: number) => {
    const selectedOperator = dataSourceOperators.find(
      (operator) => operator.id === operatorId
    );

    if (selectedOperator) {
      setEditedOperator(selectedOperator);
      setVisibleModal(true);
    }
  };

  const deleteOperator = (id: number) => {
    const result = timetables.filter(({ operator }) => operator.id === id);

    result.length
      ? openNotificationWithIcon(
          "Błąd podczas usuwania operatora.",
          "Usuń operatora z harmonogramu.",
          "error"
        )
      : removeOperator(id);
  };

  const closeModal = () => {
    if (editedOperator) setEditedOperator(undefined);
    setVisibleModal(false);
  };

  const saveOperator = (operatorPayload: Operator) => {
    const { id } = operatorPayload;

    id ? modifyOperator(operatorPayload) : createOperator(operatorPayload);
  };

  const filteredOperators = useMemo(
    () =>
      dataSourceOperators && dataSourceOperators.length
        ? dataSourceOperators
            .map(({ id, name, surname, telephone }: Operator) => ({
              id,
              name_surname: `${name} ${surname}`,
              telephone,
            }))
            .filter(({ name_surname, telephone }) =>
              filterItem({ name_surname, telephone }, searchValue)
            )
        : [],
    [dataSourceOperators, searchValue]
  );

  return (
    <>
      {loading ? (
        <Styled.Loading size={"large"} />
      ) : (
        <>
          <Styled.Container>
            <Header>
              <SearchInput
                prefix={<SearchOutlined />}
                placeholder="Szukaj..."
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <Button onClick={() => setVisibleModal(true)} icon={<PlusIcon />}>
                Dodaj operatora
              </Button>
            </Header>

            <Table
              rowKey={(record: Operator) => record.id}
              dataSource={filteredOperators}
              columns={Columns({ deleteOperator, editOperator })}
              locale={{ emptyText: "Brak danych." }}
              pagination={{ pageSize: 10 }}
              scroll={{ y: "calc(100vh - 300px)" }}
            />
          </Styled.Container>

          <ModalModification
            visible={visibleModal}
            user={editedOperator}
            onClose={closeModal}
            onSave={saveOperator}
          />
        </>
      )}
    </>
  );
};

export default OperatorsManagement;
