import styled from "styled-components";
import { Input } from "antd";

export const Header = styled.div`
  display: flex;
`;

export const SearchInput = styled(Input)`
  border-radius: 15px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 20px;
`;
