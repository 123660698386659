import AWS from "aws-sdk";
import { AWS as AWSConstants } from "../../constants/AWS";

const setAWSConfig = (user: string) => {
  AWS.config.region = AWSConstants.Cognito.Region;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: AWSConstants.Cognito.IdentityPoolId,
    Logins: {
      "cognito-idp.eu-central-1.amazonaws.com/eu-central-1_vTbNeXYBQ": user,
    },
  });
};

const getS3PhotoSrc = (bucket: string, path: string): Promise<string> => {
  return new Promise<string>((resolve) => {
    const s3 = new AWS.S3({
      apiVersion: AWSConstants.apiVersion,
      params: { Bucket: bucket },
    });

    const params = {
      Bucket: bucket,
      Key: path,
    };

    s3.getObject(params, (error, data) => {
      if (error) console.log(error, error.stack);
      else {
        resolve("data:image/png;base64," + encode(data.Body));
      }
    });
  });
};

const encode = (data: AWS.S3.Body | undefined) => {
  if (data) {
    const buf = Buffer.from(data as string);

    return buf.toString("base64");
  }
};

export { setAWSConfig, getS3PhotoSrc };
