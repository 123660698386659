import React, { FC, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import {
  ADD_OPERATOR,
  DELETE_OPERATOR,
  GET_OPERATORS,
  UPDATE_OPERATOR,
} from "../graphQL/operators";

import { message } from "antd";
import { openNotificationWithIcon } from "../components/common/Notification/Notification";

interface IProps {
  loading: boolean;
  dataSourceOperators: Operator[];
  removeOperator: (id: number) => void;
  modifyOperator: (operatorPayload: Operator) => void;
  createOperator: (operatorPayload: Operator) => void;
}

const OperatorContext = React.createContext<IProps>({} as IProps);

const OperatorProvider: FC = (props) => {
  const { data, loading } = useQuery(GET_OPERATORS, {
    fetchPolicy: "no-cache",
  });
  const [deleteOperator] = useMutation(DELETE_OPERATOR);
  const [updateOperator] = useMutation(UPDATE_OPERATOR);
  const [addOperator] = useMutation(ADD_OPERATOR);

  const [dataSourceOperators, setDataSourceOperators] = useState<Operator[]>(
    []
  );

  useEffect(() => {
    if (data) {
      const { listOperators } = data;
      setDataSourceOperators(listOperators);
    }
  }, [data]);

  const removeOperator = (id: number) => {
    deleteOperator({
      variables: { input: { id } },
    })
      .then(() => {
        const result = dataSourceOperators.filter(
          ({ id: operatorId }) => operatorId !== id
        );

        setDataSourceOperators(result);
        openNotificationWithIcon(
          "Operator został pomyślnie usunięty.",
          "",
          "success"
        );
      })
      .catch(() => {
        message.error("Nie można usunąć wybranego operatora.");
      });
  };

  const modifyOperator = (operatorPayload: Operator) => {
    updateOperator({
      variables: { input: operatorPayload },
    })
      .then(() => {
        const { id } = operatorPayload;
        const operators = [...dataSourceOperators];

        const indexOperator = operators.findIndex(
          (operator) => operator.id === id
        );

        if (indexOperator !== -1) {
          operators.splice(indexOperator, 1, operatorPayload);

          setDataSourceOperators(operators);
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  const createOperator = (operatorPayload: Operator) => {
    addOperator({
      variables: { input: operatorPayload },
    })
      .then(({ data }) => {
        const newOperator = {
          ...operatorPayload,
          id: data.createOperator.id,
        };

        setDataSourceOperators([...dataSourceOperators, newOperator]);
      })
      .catch((error) => {
        throw error;
      });
  };

  const value = {
    loading,
    dataSourceOperators,
    removeOperator,
    modifyOperator,
    createOperator,
  };

  return <OperatorContext.Provider value={value} {...props} />;
};

const useOperatorManagement = () => {
  const context = React.useContext(OperatorContext);

  if (context === undefined) {
    throw new Error(`useOperator must be used within a OperatorProvider`);
  }

  return context;
};

export { useOperatorManagement, OperatorProvider };
