import React, { useEffect, useState } from "react";
import { DatePicker, Form, Modal, Select } from "antd";
import { formLayout } from "../../../constants/layout";

import { useCars } from "../../../context/carsContext";
import { useRoutes } from "../../../context/routesContext";
import { useOperatorManagement } from "../../../context/operatorsContext";
import { useDriver } from "../../../context/driversContext";

import moment from "moment";
import { useQuery } from "@apollo/client";
import { GET_PROJECTS } from "../../../graphQL/projects";
import {
  DATE_FORMAT,
  DATE_FORMAT_WITHOUT_SECONDS,
} from "../../../constants/dateFormat";
const { Option } = Select;

interface IProps {
  visible: boolean;
  timetable?: Timetable;
  onClose: () => void;
  saveTimetable: (
    newTimetableData: NewTimetableData,
    timetableId?: number
  ) => void;
}

const TimetableManagementModal = ({
  visible,
  timetable,
  onClose,
  saveTimetable,
}: IProps) => {
  const [projects, setProjects] = useState<Project[]>([]);
  const { data } = useQuery(GET_PROJECTS);
  const { cars } = useCars();
  const { routes } = useRoutes();
  const { dataSourceOperators } = useOperatorManagement();
  const { driversList } = useDriver();

  const [form] = Form.useForm();

  useEffect(() => {
    if (data) {
      const { listProjects } = data;
      setProjects(listProjects);
    }
  }, [data]);

  useEffect(() => {
    if (timetable) {
      const {
        car: { id: car_id },
        driver: { id: driver_id },
        operator: { id: operator_id },
        route: { id: route_id },
        project: { id: project_id },
        from_time,
        to_time,
      } = timetable;

      form.setFieldsValue({
        from_time: moment(from_time),
        to_time: moment(to_time),
        project_id,
        route_id,
        operator_id,
        car_id,
        driver_id,
      });
    }
  }, [timetable]);

  const closeModal = () => {
    form.resetFields();
    onClose();
  };

  const createNewTimetableDataStructure = (value: TimetableFormFields) => {
    const {
      from_time,
      to_time,
      car_id,
      driver_id,
      operator_id,
      project_id,
      route_id,
    } = value;

    const route = routes?.find(({ id }) => id === route_id);
    const car = cars?.find(({ id }) =>
      id.toLowerCase().includes(car_id.toLowerCase())
    );
    const operator = dataSourceOperators?.find(({ id }) => id === operator_id);
    const driver = driversList.find(({ id }) => id === driver_id);
    const project = projects.find(({ id }) => id === project_id);

    if (route && car && operator && driver && project) {
      return {
        from_time: moment(from_time).format(DATE_FORMAT),
        to_time: moment(to_time).format(DATE_FORMAT),
        project,
        car,
        route,
        operator,
        driver,
      };
    }
  };

  const onSaveTimetable = () =>
    form.validateFields().then((value) => {
      const timetableId = timetable?.id;
      const newTimetableData = createNewTimetableDataStructure(value);

      if (newTimetableData) {
        saveTimetable(newTimetableData, timetableId);
        closeModal();
      }
    });

  const getSortedDriversBySurname = () =>
    [...driversList].sort((a, b) => a.surname.localeCompare(b.surname));

  const getSortedRoutes = () =>
    [...routes].sort((a, b) => a.layer_name.localeCompare(b.layer_name));

  return (
    <Modal
      title={"Zarządzanie harmonogramem"}
      visible={visible}
      onCancel={closeModal}
      cancelText={"Anuluj"}
      maskClosable={false}
      width={670}
      okText={"Zapisz"}
      onOk={onSaveTimetable}
    >
      <Form form={form} {...formLayout}>
        <Form.Item
          label="Rozpoczęcie prac:"
          name="from_time"
          rules={[{ required: true, message: "Podaj czas rozpoczęcia prac" }]}
        >
          <DatePicker showTime format={DATE_FORMAT_WITHOUT_SECONDS} />
        </Form.Item>
        <Form.Item
          label="Zakończenie prac"
          name="to_time"
          rules={[{ required: true, message: "Podaj czas zakończenia prac" }]}
        >
          <DatePicker showTime format={DATE_FORMAT_WITHOUT_SECONDS} />
        </Form.Item>
        <Form.Item
          label="Samochód"
          name="car_id"
          rules={[{ required: true, message: "Wybierz samochód" }]}
        >
          <Select>
            {cars && cars.length
              ? cars.map(({ id }) => <Option value={id}>{id}</Option>)
              : null}
          </Select>
        </Form.Item>
        <Form.Item
          label="Kierowca"
          name="driver_id"
          rules={[{ required: true, message: "Wybierz kierowcę" }]}
        >
          <Select>
            {driversList && driversList.length
              ? getSortedDriversBySurname().map(({ id, name, surname }) =>
                  id ? (
                    <Option value={id}>
                      {surname} {name}
                    </Option>
                  ) : null
                )
              : null}
          </Select>
        </Form.Item>
        <Form.Item
          label="Operator"
          name="operator_id"
          rules={[{ required: true, message: "Wybierz operatora" }]}
        >
          <Select>
            {dataSourceOperators && dataSourceOperators.length
              ? dataSourceOperators.sort().map(({ id, name, surname }) =>
                  id ? (
                    <Option value={id}>
                      {name} {surname}
                    </Option>
                  ) : null
                )
              : null}
          </Select>
        </Form.Item>
        <Form.Item
          label="Projekt"
          name="project_id"
          rules={[{ required: true, message: "Wybierz Projekt" }]}
        >
          <Select>
            {projects && projects.length
              ? projects.map(({ id, project_name }) =>
                  id ? <Option value={id}>{project_name}</Option> : null
                )
              : null}
          </Select>
        </Form.Item>
        <Form.Item
          label="Trasa"
          name="route_id"
          rules={[{ required: true, message: "Wybierz trasę" }]}
        >
          <Select>
            {routes && routes.length
              ? getSortedRoutes().map(({ id, layer_name }) =>
                  id ? <Option value={id}>{layer_name}</Option> : null
                )
              : null}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TimetableManagementModal;
