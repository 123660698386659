import styled from "styled-components";
import { Form as AntForm, Button as AntButton, Input as AntInput } from "antd";

export const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoginCard = styled.div`
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  z-index: 2;
  display: grid;
  grid-template-columns: auto;
  grid-gap: 10px;
  padding: 10px;
  margin: 10px;
`;

export const Form = styled(AntForm)`
  display: grid;
  grid-template-columns: auto;
  grid-gap: 5px;
`;

export const Input = styled(AntInput)``;

export const Button = styled(AntButton)`
  margin-top: 5px;
`;

export const Error = styled.span`
  text-align: center;
  font-size: 14px;
  color: #c4391a;
`;
