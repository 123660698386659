import styled from "styled-components";
import { Menu as AntMenu } from "antd";

export const Container = styled.div`
  background-color: #439504;
  border-radius: 13px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row;
`;

export const Menu = styled(AntMenu)`
  width: 100%;
  margin-left: 50px;

  .ant-menu-item-selected {
    background-color: #90be6d;
  }

  .ant-menu-item-active:hover {
    background-color: #90be6d;
  }

  span {
    color: black;
  }

  li::after {
    border: 0 !important;
  }
`;

export const LogoutContainer = styled.div`
  background-color: #fff;
  border-radius: 0 13px 13px 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 10px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.85);
  transition: 300ms all;

  :hover {
    color: rgba(0, 0, 0, 0.45);
  }
`;

export const LogoutLabel = styled.span`
  margin-right: 5px;
`;
