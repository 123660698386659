import React from "react";
import { Router } from "./components/Router/Router";

import { ApolloProvider } from "@apollo/client";
import { useClient } from "./hooks/useClient";

const App = () => {
  const client = useClient();

  return (
    <ApolloProvider client={client}>
      <Router />
    </ApolloProvider>
  );
};

export default App;
