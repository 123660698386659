import styled from "styled-components";
import EditIcon from "../../assets/icons/edit_icon.svg";
import TrashIcon from "../../assets/icons/trash_icon.svg";

export const Edit = styled.div`
  background-image: url(${EditIcon});
  height: 19px;
  width: 19px;
`;

export const Trash = styled.div`
  background-image: url(${TrashIcon});
  height: 18px;
  width: 14px;
`;
