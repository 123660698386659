import styled from "styled-components";
import { Collapse as AntCollapse, Badge as AntBadge } from "antd";

const { Panel } = AntCollapse;

interface StatusProps {
  color?: string;
}

export const Collapse = styled(AntCollapse)``;

export const CollapsePanel = styled(Panel)``;

export const AlertsHeader = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
  padding: 0 10px;
`;

export const AlertContainer = styled.div<StatusProps>`
  border-radius: 10px;
  background-color: ${(props) => (props.color ? props.color : "#fff")};
  color: ${(props) => (props.color ? "#fff" : "#000")};
  opacity: 0.5;
  padding: 10px;
  margin-bottom: 10px;
`;

export const Badge = styled(AntBadge)`
  margin-left: 10px;
`;
