import React, { FC, useEffect, useState } from "react";
import { useQuery, useApolloClient, useMutation } from "@apollo/client";
import {
  CREATE_ROUTE,
  DELETE_ROUTE,
  GET_FEATURE_COLLECTION,
  GET_ROUTES,
  UPDATE_ROUTE,
} from "../graphQL/routes";
import { message } from "antd";
import { openNotificationWithIcon } from "../components/common/Notification/Notification";

interface IProps {
  loading: boolean;
  routes: LayerItem[];
  addRoute: (route: LayerItem) => void;
  modifyRoute: (route: LayerItem) => void;
  removeRoute: (id: number) => void;
  setRouteVisibility: (id: number, isVisible: boolean) => void;
  setAllRouteVisibility: (isVisible: boolean) => void;
}

const RoutesContext = React.createContext<IProps>({} as IProps);

const RoutesProvider: FC = (props) => {
  const client = useApolloClient();

  const { data, loading } = useQuery(GET_ROUTES, { fetchPolicy: "no-cache" });
  const [createRoute] = useMutation(CREATE_ROUTE);
  const [updateRoute] = useMutation(UPDATE_ROUTE);
  const [deleteRoute] = useMutation(DELETE_ROUTE);

  const [routes, setRoutes] = useState<LayerItem[]>([]);

  useEffect(() => {
    if (data) {
      queryRoutesGeometry().then(setRoutes);
    }
  }, [data]);

  useEffect(() => {
    if (routes.length) {
      const routesKeys = routes.map(({ id, isActive }) => ({
        id,
        isActive,
      }));

      localStorage.setItem("routesKeys", JSON.stringify(routesKeys));
    }
  }, [routes]);

  const queryRoutesGeometry = async (): Promise<LayerItem[]> => {
    const geometries: LayerItem[] = [];

    for (const item of data.listRoutes) {
      const { layer_name, shp_filename, id } = item;

      await client
        .query({
          query: GET_FEATURE_COLLECTION,
          variables: {
            layer_name,
            shp_filename,
          },
        })
        .then(({ data }) => {
          if (data.callLambdaReadFCfromSHP) {
            const geometry = JSON.parse(data.callLambdaReadFCfromSHP)
              .features[0].geometry;

            geometries.push({
              ...item,
              geometry,
              isActive: getVisibility(id),
            });
          }
        })
        .catch(() => console.log("błąd odczytu geometrii trasy"));
    }

    return geometries;
  };

  const addGeometry = async (newRoute: LayerItem) => {
    const { layer_name, shp_filename } = newRoute;

    return await client
      .query({
        query: GET_FEATURE_COLLECTION,
        variables: {
          layer_name,
          shp_filename,
        },
      })
      .then(({ data }) => {
        if (data.callLambdaReadFCfromSHP) {
          const geometry = JSON.parse(data.callLambdaReadFCfromSHP).features[0]
            .geometry;

          const payload: LayerItem = {
            ...newRoute,
            geometry,
          };

          return payload;
        }
      });
  };

  const addRoute = async (route: LayerItem) => {
    createRoute({
      variables: { input: route },
    })
      .then(({ data }) => {
        const newRoute = {
          ...route,
          id: data.createRoute.id,
        };

        addGeometry(newRoute).then((response) => {
          if (response) setRoutes([...routes, response]);
        });
      })
      .catch((error) => {
        throw error;
      });
  };

  const modifyRoute = (route: LayerItem) => {
    updateRoute({
      variables: { input: route },
    })
      .then(() => {
        addGeometry(route).then((response) => {
          if (response) {
            const routesList = [...routes];
            const indexRoute = routes.findIndex(
              (route) => route.id === response.id
            );

            if (indexRoute !== -1) {
              routesList.splice(indexRoute, 1, response);

              setRoutes(routesList);
            }
          }
        });
      })
      .catch(() => {
        message.error("Nie można edytować wybranej trasy.");
      });
  };

  const removeRoute = (id: number) => {
    deleteRoute({
      variables: { input: { id } },
    })
      .then(() => {
        const result = routes.filter(({ id: routeId }) => routeId !== id);

        setRoutes(result);
        openNotificationWithIcon(
          "Trasa została pomyślnie usunięta.",
          "",
          "success"
        );
      })
      .catch(() => {
        message.error("Nie można usunąć wybranej trasy.");
      });
  };

  const setRouteVisibility = (id: number, isVisible: boolean) => {
    const tempRoutes = [...routes];

    const indexRoute = tempRoutes.findIndex((route) => route.id === id);

    if (indexRoute !== -1) {
      tempRoutes[indexRoute].isActive = isVisible;

      setRoutes(tempRoutes);
    }
  };

  const setAllRouteVisibility = (isVisible: boolean) => {
    const tempRoutes = [...routes];

    tempRoutes.map((item) => (item.isActive = isVisible));

    setRoutes(tempRoutes);
  };

  const getVisibility = (id: number) => {
    const routesVisibilityKeys = localStorage.getItem("routesKeys");

    if (routesVisibilityKeys) {
      return JSON.parse(routesVisibilityKeys).find(
        (item: { id: number }) => item.id === id
      ).isActive;
    }

    return true;
  };

  const value = {
    loading,
    routes,
    addRoute,
    modifyRoute,
    removeRoute,
    setRouteVisibility,
    setAllRouteVisibility,
  };

  return <RoutesContext.Provider value={value} {...props} />;
};

const useRoutes = () => {
  const context = React.useContext(RoutesContext);

  if (context === undefined) {
    throw new Error(`useRoutes must be used within a RoutesProvider`);
  }

  return context;
};

export { RoutesProvider, useRoutes };
