import styled from "styled-components";
import {
  Input,
  Select as AntSelect,
  DatePicker as AntDatePicker,
  Button as AntButton,
  Spin,
} from "antd";

export const SearchInput = styled(Input)`
  border-radius: 15px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  margin-left: 10px;
`;

export const FilterContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const Select = styled(AntSelect)`
  height: 32px;
  width: 300px;
  .ant-select-selector {
    border-radius: 15px !important;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }
`;

export const DatePicker = styled(AntDatePicker)`
  height: 32px;
  width: 300px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  margin-left: 10px;
`;

export const Loading = styled(Spin)`
  margin: auto;
`;

export const Container = styled.div``;

export const ButtonsContainer = styled.div`
  text-align: center;
  min-width: 80px;
`;

export const Button = styled(AntButton)`
  margin-right: 5px;
`;

export const ButtonTitle = styled.div`
  font-size: 14px;
  align-self: center;
  font-weight: 600;
`;
