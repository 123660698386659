import React from "react";

import { Statuses } from "./constants/statuses";
import * as Styled from "./Alerts.styles";
import { InfoCircleOutlined } from "@ant-design/icons";

interface IProps {
  statuses: string[];
}

const Alerts = ({ statuses }: IProps) => {
  const displayPanelHeader = (length: number) => (
    <>
      Pokaż powiadomienia
      <Styled.Badge count={length} />
    </>
  );

  const displayAlerts = (statuses: Status[]) =>
    statuses.map((item, index) => (
      <Styled.AlertContainer color={item.color} key={index}>
        <InfoCircleOutlined /> {item.description}
      </Styled.AlertContainer>
    ));

  const displayHeader = () => (
    <Styled.AlertsHeader>
      {statuses.map((item, index) => (index === 0 ? item : ` / ${item}`))}
    </Styled.AlertsHeader>
  );

  const matchingStatuses: Status[] = statuses
    .map((item) => Statuses.find((status: Status) => status.symbol === item))
    .filter((item): item is Status => !!item);

  const defaultActiveKey = matchingStatuses.find((item) => item.isCritical)
    ? "1"
    : null;

  return (
    <Styled.Collapse
      defaultActiveKey={defaultActiveKey ? defaultActiveKey : []}
      ghost={true}
    >
      <Styled.CollapsePanel
        header={displayPanelHeader(statuses.length)}
        key="1"
      >
        {displayHeader()}
        {displayAlerts(matchingStatuses)}
      </Styled.CollapsePanel>
    </Styled.Collapse>
  );
};

export default Alerts;
