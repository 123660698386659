export const Statuses: Status[] = [
  {
    symbol: "MHI",
    description: "Wysokie zużycie pamięci",
    isCritical: true,
    color: "#f24726",
  },
  {
    symbol: "CHI",
    description: "Wysokie zużycie procesora",
    isCritical: true,
    color: "#f24726",
  },
  {
    symbol: "GQ1",
    description: "Niska jakość sygnału",
    isCritical: true,
    color: "#f24726",
  },
  {
    symbol: "GN0",
    description: "Brak sygnału GPS",
    isCritical: true,
    color: "#f24726",
  },
  {
    symbol: "INO",
    description: "System zarejestrował przerwę w połączeniu internetowym",
    isCritical: true,
    color: "#f24726",
  },
  {
    symbol: "NER",
    description: "Błąd poprawek",
    isCritical: true,
    color: "#f24726",
  },
  {
    symbol: "GER",
    description: "Brak inicjalizacji, Błąd ogólny GPS",
    isCritical: false,
    color: "#f24726",
  },
  {
    symbol: "GNO",
    description: "Brak sygnału",
    isCritical: false,
    color: "#f24726",
  },
  {
    symbol: "GQ0",
    description: "Sygnał zerowy",
    isCritical: false,
    color: "#f24726",
  },
  {
    symbol: "GQ1, GQ2",
    description: "Niska jakośc sygnału",
    isCritical: false,
    color: "#f24726",
  },
  {
    symbol: "....",
    description: "Sygnał oddala się od trasy",
    isCritical: false,
    color: "#f24726",
  },
  {
    symbol: "....",
    description: "Sygnał nie rejestruje zmian lokalizacji przez ...",
    isCritical: false,
    color: "#f24726",
  },
  {
    symbol: "KER",
    description: "Brak Inicjalizacji/Błąd ogólny kamery",
    isCritical: false,
    color: "#2d9bf0",
  },
  {
    symbol: "KLO",
    description:
      "Inicjalizacja kamery nie udała się z wymaganymi parametrami np FPS = 30",
    isCritical: false,
    color: "#2d9bf0",
  },
  {
    symbol: "???",
    description: "Kamera nie zwraca prawidłowego obrazu",
    isCritical: false,
    color: "#2d9bf0",
  },
  {
    symbol: "???",
    description: "Urządzenie nie działa",
    isCritical: false,
    color: "#652cb3",
  },
  {
    symbol: "???",
    description: "Korki",
    isCritical: false,
    color: "#09735b",
  },
  {
    symbol: "???",
    description: "Droga Zablokowana",
    isCritical: false,
    color: "#09735b",
  },
  {
    symbol: "AER",
    description: "Brak inicjalizacji/Błąd ogólny ALPR",
    isCritical: false,
    color: "#da0063",
  },
  {
    symbol: "ALI",
    description: "Błąd licencji (license)",
    isCritical: false,
    color: "#da0063",
  },
  {
    symbol: "AHI",
    description: "Powolne działanie (high pressure)",
    isCritical: false,
    color: "#da0063",
  },
  {
    symbol: "IER",
    description: "Brak sieci, Wyświetlanie na interfejsie KIEROWCY",
    isCritical: false,
    color: "#848484",
  },
  {
    symbol: "???",
    description: "Urządzenie nie uruchamia się",
    isCritical: false,
    color: "#414bb2",
  },
  {
    symbol: "???",
    description: "Urządzenie nie uruchamia się",
    isCritical: false,
    color: "black",
  },
  {
    symbol: "DAB",
    description: "Nie jest obecne (absent)",
    isCritical: false,
    color: "#09735b",
  },

  {
    symbol: "DCAL",
    description:
      "Urządzenie rejstruje kręcenie się, gdy pojazd stoi (calibration)",
    isCritical: false,
    color: "#09735b",
  },
];
