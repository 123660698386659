import ReactDOM from "react-dom";

import React from "react";
import { Select } from "ol/interaction";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Geometry } from "ol/geom";
import { pointerMove } from "ol/events/condition";
import Overlay from "ol/Overlay";
import Point from "ol/geom/Point";

import CarTooltip from "../tooltips/CarTooltip";
import { Feature } from "ol";

interface IProps {
  layer: VectorLayer<VectorSource<Geometry>>;
  tooltip: Overlay;
}

const initializeHover = ({ layer, tooltip }: IProps) => {
  const hover = new Select({
    condition: pointerMove,
    layers: [layer],
    style: (feature) => feature.get("style").hovered,
  });

  hover.setProperties({ type: "HOVER" });

  hover.on("select", ({ selected }) => {
    if (selected.length > 0) {
      const selectedFeature = selected[0];

      const layerName = selectedFeature.get("layer_name");
      const tooltipContent = selectedFeature.get("tooltipContent");

      setHoverTooltipContent(tooltipContent, layerName);
      setTooltipPosition(selectedFeature, tooltip);
    } else {
      // @ts-ignore
      tooltip.setPosition(undefined);
    }
  });

  return hover;
};

const setHoverTooltipContent = (content: string, layerName: string) => {
  const container = document.getElementById("popup-content");

  //todo add layers tooltip
  if (layerName === "CARS")
    ReactDOM.render(<CarTooltip content={content} />, container);
};

const setTooltipPosition = (feature: Feature<Geometry>, tooltip: Overlay) => {
  const geometry = feature.getGeometry() as Point;
  const coordinates = geometry.getCoordinates();

  tooltip.setPosition(coordinates);
};

export { initializeHover };
