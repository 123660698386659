import React, { useEffect, useRef, useState } from "react";
import {
  CameraOutlined,
  ExclamationCircleOutlined,
  CloseOutlined,
} from "@ant-design/icons";

import Alerts from "../Alerts/Alerts";
import { Colors } from "../../constants/colors";
import * as Styled from "./MeasuringSet.styles";
import PhotosModal from "./photosModal/PhotosModal";
import { useMeasurements } from "../../context/measurementsContext";

interface IProps {
  measurement: ParsedMeasurement;
  onSelectedMeasurement: (coordinates: number[]) => void;
}

const MeasuringSet = ({ measurement, onSelectedMeasurement }: IProps) => {
  const timeout = useRef<NodeJS.Timeout>();
  const { removeMeasurement } = useMeasurements();
  const [connectionAlert, setConnectionAlert] = useState<ConnectionAlert>();
  const [selectedCarId, setSelectedCarId] = useState<string>();

  useEffect(() => {
    if (
      !timeout.current ||
      (connectionAlert &&
        connectionAlert.isConnected !== measurement.isActive &&
        timeout.current)
    ) {
      if (timeout && timeout.current) clearTimeout(timeout.current);

      setConnectionAlert(createAlertPayload(measurement, true));

      timeout.current = setTimeout(() => {
        setConnectionAlert(createAlertPayload(measurement, false));
      }, 5000);
    }
  }, [measurement]);

  const createAlertPayload = (item: Measurement, showAlert: boolean) => ({
    id: item.UUID_row,
    isConnected: item.isActive,
    showAlert,
  });

  const getConnectionAlertColor = (
    connectionAlert: ConnectionAlert | undefined
  ) =>
    connectionAlert && connectionAlert.showAlert
      ? connectionAlert.isConnected
        ? Colors.connection.SUCCESS
        : Colors.connection.ERROR
      : "#fff";

  const renderConnectionAlert = (
    connectionAlert: ConnectionAlert | undefined
  ) =>
    connectionAlert && connectionAlert.showAlert ? (
      <Styled.ConnectionAlert>
        {connectionAlert.isConnected
          ? `POŁĄCZENIE PRZYWRÓCONE`
          : `BRAK POŁĄCZENIA`}
      </Styled.ConnectionAlert>
    ) : null;

  const renderPackagesAlert = () =>
    packages > 0 ? (
      <Styled.PackagesContainer>
        <Styled.Badge count={packages} offset={[-5, 5]}>
          <Styled.PackagesContentContainer>
            <ExclamationCircleOutlined /> Oczekujące paczki do akceptacji
          </Styled.PackagesContentContainer>
        </Styled.Badge>
      </Styled.PackagesContainer>
    ) : null;

  const onModalHide = () => setSelectedCarId(undefined);

  const onRemoveSet = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent> | MouseEvent,
    id: number
  ) => {
    e.stopPropagation();

    removeMeasurement(id);
  };

  const {
    UUID_row,
    car,
    route,
    driver,
    gps_coordinates_Y,
    gps_coordinates_X,
    status,
    isActive,
    packages,
  } = measurement;

  return (
    <Styled.CardContainer>
      <Styled.Card
        color={getConnectionAlertColor(connectionAlert)}
        onClick={() =>
          onSelectedMeasurement([gps_coordinates_X, gps_coordinates_Y])
        }
      >
        <Styled.ContainerLine>
          <Styled.Line color={route.layer_color} />
        </Styled.ContainerLine>

        <Styled.MeasuringSetContainer>
          <Styled.Stopwatch />

          <Styled.MeasuringSetContentContainer>
            <Styled.ContainerHeader>
              <Styled.HeaderContainer>
                <Styled.Header>{`ZESTAW POMIAROWY ID ${car.id}`}</Styled.Header>

                <Styled.ConnectionStatusContainer>
                  <Styled.RemoveSetButton
                    onClick={(e) => onRemoveSet(e, UUID_row)}
                  >
                    <CloseOutlined />
                  </Styled.RemoveSetButton>

                  <Styled.ConnectionStatus
                    color={
                      isActive
                        ? Colors.connection.SUCCESS
                        : Colors.connection.ERROR
                    }
                  />
                </Styled.ConnectionStatusContainer>
              </Styled.HeaderContainer>
            </Styled.ContainerHeader>

            <Styled.InfoContainer>
              <Styled.InfoContentContainer>
                <Styled.OperatorHeader>{`${driver.surname} ${driver.name} `}</Styled.OperatorHeader>
                <Styled.PhoneNumber>{`${driver.telephone}`}</Styled.PhoneNumber>
                <Styled.RouteHeader>{`Trasa ${route.common_name}`}</Styled.RouteHeader>
              </Styled.InfoContentContainer>

              <Styled.AlertContainer>
                {renderConnectionAlert(connectionAlert)}
              </Styled.AlertContainer>
            </Styled.InfoContainer>
          </Styled.MeasuringSetContentContainer>
        </Styled.MeasuringSetContainer>
      </Styled.Card>

      {renderPackagesAlert()}

      <Styled.PhotosButton onClick={() => setSelectedCarId(car.id)}>
        <Styled.PhotosButtonTitle>Pokaż zdjęcia</Styled.PhotosButtonTitle>
        <CameraOutlined />
      </Styled.PhotosButton>

      <Alerts statuses={status} />

      <PhotosModal onHide={onModalHide} carId={selectedCarId} />
    </Styled.CardContainer>
  );
};

export default MeasuringSet;
