import React from "react";
import * as Styled from "./columns.styles";
import { ColumnsType } from "antd/es/table";
import { Button, Popconfirm } from "antd";
import { compare } from "natural-orderby";

interface IProps {
  deleteOperator: (operatorId: number) => void;
  editOperator: (operatorId: number) => void;
}

const Columns = ({
  deleteOperator,
  editOperator,
}: IProps): ColumnsType<OperatorTable> => [
  {
    title: "Imię i nazwisko",
    dataIndex: "name_surname",
    key: "name_surname",
    filterMultiple: false,
    sorter: (a, b) => compare()(a.name_surname, b.name_surname),
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "Numer telefonu",
    dataIndex: "telephone",
    key: "telephone",
    filterMultiple: false,
    sorter: (a, b) => compare()(a.telephone, b.telephone),
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "",
    dataIndex: "edit",
    width: 60,
    key: "edit",
    render: (_, { id }) => (
      <Button
        type={"link"}
        icon={<Styled.Edit />}
        onClick={() => editOperator(id)}
      />
    ),
  },
  {
    title: "",
    dataIndex: "delete",
    width: 60,
    key: "delete",
    render: (_, { id }) => (
      <Popconfirm
        title="Czy napewno usunąć?"
        onConfirm={() => deleteOperator(id)}
        okText="Usuń"
        cancelText="Anuluj"
        placement={"topRight"}
      >
        <Button type={"link"} icon={<Styled.Trash />} />
      </Popconfirm>
    ),
  },
];

export default Columns;
