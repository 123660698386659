import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";

import { message } from "antd";
import { getCookie } from "../../../helpers/Cookies";

export const PrivateRoute = ({ component: Component, ...rest }: RouteProps) => {
  if (!Component) return null;

  return (
    <Route
      {...rest}
      render={(props) => {
        const isLoggedIn = getCookie("access_token");

        if (!isLoggedIn) {
          message.warning("Twoja sesja wygasła, zaloguj się ponownie.");
        }

        return isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }}
    />
  );
};
