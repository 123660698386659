import React from "react";
import styled from "styled-components";
import { Table as AntTable } from "antd";
import EditIcon from "../icons/edit_icon.svg";
import TrashIcon from "../icons/trash_icon.svg";

export const Table = styled(({ ...props }) => <AntTable {...props} />)`
  .ant-table-cell {
    background-color: transparent;
    border: none;
  }

  .ant-table-header {
    table {
      table-layout: auto !important;
    }
  }

  .ant-table-tbody {
    font-size: 14px;
  }

  .ant-table-thead {
    font-size: 18px;
    line-height: 22px;
  }

  .ant-table-tbody tr:nth-child(2n + 1) {
    background-color: #bcebac;

    :hover {
      background-color: #bcebac;
    }
  }

  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: none;
    cursor: pointer;
  }
  width: 100%;
`;

export const Edit = styled.div`
  background-image: url(${EditIcon});
  height: 19px;
  width: 19px;
`;

export const Trash = styled.div`
  background-image: url(${TrashIcon});
  height: 18px;
  width: 14px;
`;
