import Style from "ol/style/Style";
import RenderEvent from "ol/render/Event";
import { unByKey } from "ol/Observable";
import { getVectorContext } from "ol/render";
import { easeOut } from "ol/easing";
import CircleStyle from "ol/style/Circle";
import Stroke from "ol/style/Stroke";
import Map from "ol/Map";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Geometry from "ol/geom/Geometry";
import Feature from "ol/Feature";
import Circle from "ol/style/Circle";
import Fill from "ol/style/Fill";
import MultiLineString from "ol/geom/MultiLineString";
import LineString from "ol/geom/LineString";

const animatePosition = (
  feature: Feature<Geometry>,
  layer: VectorLayer<VectorSource<Geometry>>,
  map: Map
) => {
  const duration = 3000;
  const start = Date.now();
  const flashGeom = feature.getGeometry()?.clone();
  const listenerKey = layer.on("postrender", animate);

  function animate(event: RenderEvent) {
    if (event.frameState) {
      const frameState = event.frameState;
      const elapsed = frameState.time - start;

      if (elapsed >= duration) unByKey(listenerKey);

      const vectorContext = getVectorContext(event);
      const elapsedRatio = elapsed / duration;

      const radius = easeOut(elapsedRatio) * 25 + 5;
      const opacity = easeOut(1 - elapsedRatio);

      const style = new Style({
        image: new CircleStyle({
          radius,
          stroke: new Stroke({
            color: `rgba(255, 0, 0, ${opacity})`,
            width: 0.25 + opacity,
          }),
        }),
      });

      vectorContext.setStyle(style);
      if (flashGeom) vectorContext.drawGeometry(flashGeom);

      if (map) map.render();
    }
  }
};

const queryFeatureStyle = (
  feature: Feature<Geometry>,
  geometry: ObjectGeometry,
  layer_color: string
) => {
  const styles = {
    default: [
      new Style({
        stroke: new Stroke({
          color: "#000",
          width: 10,
        }),
        image: new Circle({
          fill: new Fill({
            color: "#000",
          }),
          radius: 11,
        }),
      }),
      new Style({
        stroke: new Stroke({
          color: layer_color,
          width: 8,
        }),
        image: new Circle({
          fill: new Fill({
            color: layer_color,
          }),
          radius: 10,
        }),
      }),
    ],
    hovered: [
      new Style({
        image: new Circle({
          fill: new Fill({
            color: "#6d6d6d",
          }),
          radius: 13,
        }),
      }),
    ],
  };

  if (geometry.type === "MultiLineString") {
    const featureGeometry = feature.getGeometry() as MultiLineString;

    featureGeometry.getLineStrings().forEach((item: LineString) => {
      const arrowsStyle = queryArrowsStyle(item, layer_color);

      styles["default"].push(...arrowsStyle);
    });
  }

  return styles;
};

const queryArrowsStyle = (
  featureGeometry: LineString,
  color: string
): Style[] => {
  const arrowsStyle: Style[] = [];

  featureGeometry.forEachSegment((start, end) => {
    const dx = end[0] - start[0];
    const dy = end[1] - start[1];
    const rotation = Math.atan2(dy, dx);
    const firstLineString = new LineString([end, [end[0] - 2, end[1] + 2]]);

    firstLineString.rotate(rotation, end);
    const secondLineString = new LineString([end, [end[0] - 2, end[1] - 2]]);
    secondLineString.rotate(rotation, end);

    const stroke = new Stroke({
      color,
      width: 8,
    });
    const strokeBorder = new Stroke({
      color: "#000",
      width: 10,
    });

    arrowsStyle.push(
      new Style({
        geometry: firstLineString,
        stroke: strokeBorder,
      }),
      new Style({
        geometry: secondLineString,
        stroke: strokeBorder,
      }),
      new Style({
        geometry: firstLineString,
        stroke: stroke,
      }),
      new Style({
        geometry: secondLineString,
        stroke: stroke,
      })
    );
  });

  return arrowsStyle;
};

export { animatePosition, queryFeatureStyle };
