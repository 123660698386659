import React from "react";

interface IProps {
  content: string;
}

const CarTooltip = ({ content }: IProps) => (
  <>
    <p>Identyfikator samochodu</p>
    <p>{content}</p>
  </>
);

export default CarTooltip;
