import React, { useEffect, useState } from "react";
import { Modal } from "antd";

import * as Styled from "./PhotosModalt.styles";
import { usePhotos } from "../../../context/photosContext";
import { getS3PhotoSrc } from "../../../providers/helpers/AWSMethods";
import { PhotoFallback } from "../../../constants/images";
import { EyeOutlined } from "@ant-design/icons";

interface IProps {
  carId: string | undefined;
  onHide: () => void;
}

const PhotosModal = ({ carId, onHide }: IProps) => {
  const { photoSets } = usePhotos();
  const [leftPhoto, setLeftPhoto] = useState<PhotoPayload>();
  const [rightPhoto, setRightPhoto] = useState<PhotoPayload>();

  useEffect(() => {
    if (carId) {
      const matchingPhotoSet = photoSets.find(
        (item) => item.UUID_row === carId.toString()
      );

      if (matchingPhotoSet) {
        matchingPhotoSet.photos.forEach((item) => {
          if (item.camera === "L") setPhoto(item, leftPhoto, setLeftPhoto);
          else if (item.camera === "R")
            setPhoto(item, rightPhoto, setRightPhoto);
        });
      }
    }
  }, [carId, photoSets]);

  const setPhoto = (
    item: PhotoPayload,
    existingPhoto: PhotoPayload | undefined,
    onSetPhoto: (item: PhotoPayload) => void
  ) => {
    if (!existingPhoto || item.path !== existingPhoto.path)
      getS3PhotoSrc(item.bucket, item.path).then((src) => {
        const mappedPhoto = {
          ...item,
          src,
        };

        onSetPhoto(mappedPhoto);
      });
  };

  const renderTitle = () => (
    <>
      Zdjęcia zestawu pomiarowego{" "}
      <Styled.MeasuringDeviceNumber>
        {carId ? carId : ""}
      </Styled.MeasuringDeviceNumber>
    </>
  );

  const renderPreview = () => (
    <div>
      <EyeOutlined />
      <Styled.Text>Podgląd</Styled.Text>
    </div>
  );

  return (
    <>
      <Modal
        title={renderTitle()}
        visible={Boolean(carId)}
        onCancel={onHide}
        onOk={onHide}
      >
        <Styled.ImageGroup>
          <Styled.ImageGroupContainer>
            <Styled.Title>Strona lewa:</Styled.Title>
            <Styled.PhotoContainer>
              <Styled.Photo
                src={leftPhoto ? leftPhoto.src : ""}
                fallback={PhotoFallback}
                preview={{
                  mask: renderPreview(),
                }}
              />
            </Styled.PhotoContainer>
          </Styled.ImageGroupContainer>

          <Styled.ImageGroupContainer>
            <Styled.Title>Strona prawa:</Styled.Title>
            <Styled.PhotoContainer>
              <Styled.Photo
                src={rightPhoto ? rightPhoto.src : ""}
                fallback={PhotoFallback}
                preview={{
                  mask: renderPreview(),
                }}
              />
            </Styled.PhotoContainer>
          </Styled.ImageGroupContainer>
        </Styled.ImageGroup>
      </Modal>
    </>
  );
};

export default PhotosModal;
