import React from "react";

import * as Styled from "./MeasuringSetsView.styles";
import MeasuringSet from "../MeasuringSet/MeasuringSet";

interface IProps {
  measurements: ParsedMeasurement[];
  onSelectedMeasurement: (coordinates: number[]) => void;
}

const MeasuringSetsView = ({ measurements, onSelectedMeasurement }: IProps) => {
  const renderMeasurementsSet = () =>
    measurements.map((item) => (
      <MeasuringSet
        key={item.UUID_row}
        measurement={item}
        onSelectedMeasurement={onSelectedMeasurement}
      />
    ));

  return (
    <Styled.Container>
      {measurements.length ? (
        renderMeasurementsSet()
      ) : (
        <Styled.NoData>Brak danych zestawów pomiarowych</Styled.NoData>
      )}
    </Styled.Container>
  );
};

export default MeasuringSetsView;
