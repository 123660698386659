import styled from "styled-components";
import { Checkbox as AntCheckbox, Button } from "antd";

export const PickerContainer = styled.div`
  position: absolute;
  top: 120px;
  left: 60px;
  width: 400px;
`;

export const Container = styled.div`
  background-color: rgba(255, 255, 255, 0.85);
  padding: 5px;
  position: absolute;
  top: 110px;
  left: 50px;
  border-radius: 10px;
  width: 300px;
`;

interface RoutesConfigurationContainerProps {
  isCollapsed: boolean;
}

export const RoutesConfigurationContainer = styled.div<RoutesConfigurationContainerProps>`
  height: 400px;
  display: ${(props) => (props.isCollapsed ? "none" : "block")};
  padding: 10px;
  overflow-y: auto;
`;

export const HeaderContainer = styled.div`
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
`;

export const ToggleButton = styled(Button)``;

export const RouteConfigurationContentContainer = styled.div``;

interface LineProps {
  color: string;
}

export const Line = styled.div<LineProps>`
  background-color: ${(props) => props.color};
  width: 50px;
  height: 10px;
  border-radius: 5px;
  margin-left: 10px;
  margin-right: 10px;
`;

export const Checkbox = styled(AntCheckbox)`
  width: 100%;
`;

export const CheckboxContentContainer = styled.div`
  padding: 2px;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;
