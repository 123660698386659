import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const OLMap = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 0;
`;

export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #4b535e;
  background: #fff;
  z-index: 10;
  font-size: 12px;
  font-weight: bold;
  padding: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0 5px 20px -12px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 5px 20px -12px rgba(0, 0, 0, 0.75);
  box-shadow: 0 5px 20px -12px rgba(0, 0, 0, 0.75);
`;
