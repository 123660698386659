import { gql } from "@apollo/client";

const GET_PROJECTS = gql`
  query QueryProjects {
    listProjects {
      id
      project_name
    }
  }
`;

export { GET_PROJECTS };
