import React, { FC, useEffect, useState } from "react";
import { useSubscription } from "@apollo/client";
import { PHOTO_SUBSCRIPTION } from "../graphQL/photos";

interface IProps {
  photoSets: PhotoSet[];
}

const PhotosContext = React.createContext<IProps>({} as IProps);

const PhotosProvider: FC = (props) => {
  const { data } = useSubscription(PHOTO_SUBSCRIPTION);
  const [photoSets, setPhotoSets] = useState<PhotoSet[]>([]);

  useEffect(() => {
    if (data) {
      const { onCreateImage: newImage } = data;
      const matchingPhotoSet = photoSets.find(
        (item) => item.UUID_row === newImage.UUID_row
      );

      if (matchingPhotoSet) {
        managePhotoSet(newImage);
      } else {
        addPhoto(newImage);
      }
    }
  }, [data]);

  const managePhotoSet = (newPhoto: PhotoPayload) => {
    const tempPhotos = [...photoSets];

    const photoSetIndex = tempPhotos.findIndex(
      (item) => item.UUID_row === newPhoto.UUID_row
    );

    if (photoSetIndex !== -1) {
      tempPhotos[photoSetIndex] = {
        UUID_row: newPhoto.UUID_row,
        photos: createPhotosPayload(tempPhotos[photoSetIndex].photos, newPhoto),
      };

      setPhotoSets(tempPhotos);
    }
  };

  const addPhoto = (newPhoto: PhotoPayload) => {
    const newPhotoSet: PhotoSet = {
      UUID_row: newPhoto.UUID_row,
      photos: [newPhoto],
    };

    setPhotoSets((prevPhotoSets) => [...prevPhotoSets, newPhotoSet]);
  };

  const createPhotosPayload = (
    photos: PhotoPayload[],
    newPhoto: PhotoPayload
  ): PhotoPayload[] => {
    const tempPhotos = [...photos];
    const photoIndex = tempPhotos.findIndex(
      (item) => item.camera === newPhoto.camera
    );

    if (photoIndex !== -1) {
      tempPhotos[photoIndex] = newPhoto;
    } else tempPhotos.push(newPhoto);

    return tempPhotos;
  };

  const queryPhotoSetById = (id: string): PhotoSet | undefined =>
    photoSets.find((item) => item.UUID_row === id);

  const value = {
    photoSets,
    queryPhotoSetById,
  };

  return <PhotosContext.Provider value={value} {...props} />;
};

const usePhotos = () => {
  const context = React.useContext(PhotosContext);

  if (context === undefined) {
    throw new Error(`usePhotos must be used within a PhotosProvider`);
  }

  return context;
};

export { PhotosProvider, usePhotos };
