import * as React from "react";
import { ReactElement } from "react";

import { RoutesProvider } from "../context/routesContext";
import { CarsProvider } from "../context/carsContext";
import { TimetableProvider } from "../context/timetablesContext";
import { MeasurementsProvider } from "../context/measurementsContext";
import { DriverProvider } from "../context/driversContext";
import { OperatorProvider } from "../context/operatorsContext";

interface IProps {
  children: ReactElement[];
}

function AppProviders({ children }: IProps) {
  return (
    <CarsProvider>
      <RoutesProvider>
        <OperatorProvider>
          <DriverProvider>
            <TimetableProvider>
              <MeasurementsProvider>{children}</MeasurementsProvider>
            </TimetableProvider>
          </DriverProvider>
        </OperatorProvider>
      </RoutesProvider>
    </CarsProvider>
  );
}

export { AppProviders };
