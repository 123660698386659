import styled from "styled-components";
import { Image } from "antd";

export const ImageGroup = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 40px;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.div`
  padding-bottom: 5px;
`;

export const ImageGroupContainer = styled.div``;

export const PhotoContainer = styled.div`
  display: flex;
  align-items: center;
  height: 200px;
  width: 200px;
  background-color: #f6f6f6;
`;

export const Photo = styled(Image)`
  width: 200px;
`;

export const MeasuringDeviceNumber = styled.strong``;

export const Text = styled.span`
  margin-left: 5px;
`;
