import React, { useEffect, useRef, useState } from "react";
import Map from "ol/Map";

import * as Styled from "./MapComponent.styles";
import { initializeMap } from "./helpers/map";
import { findLayer } from "./helpers/layers";
import {
  createFeature,
  manageFeatures,
  createTooltip,
} from "./helpers/features";
import Point from "ol/geom/Point";
import Geometry from "ol/geom/Geometry";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import {
  centerMapOnPoint,
  centerMapOnExtent,
  checkCoordinatesEquality,
  queryMeasurementsAsLayerItems,
} from "./helpers/operations";
import { animatePosition } from "./helpers/styles";
import { initializeHover } from "./helpers/interactions";
import RoutesPicker from "../../RoutesPicker/RoutesPicker";

interface IProps {
  routes: LayerItem[];
  measurements: Measurement[];
  selectedCoordinates: number[] | undefined;
}

const MapComponent = ({
  routes,
  measurements,
  selectedCoordinates,
}: IProps) => {
  const [map, setMap] = useState<Map>();
  const [isMapInitialized, setMapAsInitialized] = useState(false);
  const tooltipRef = useRef(null);

  useEffect(() => {
    const map = initializeMap();
    const tooltip = createTooltip(tooltipRef.current);
    map.addOverlay(tooltip);

    const carsLayer = findLayer(
      map.getLayers().getArray() as VectorLayer<VectorSource<Geometry>>[],
      "CARS"
    );

    if (carsLayer) {
      const hover = initializeHover({ layer: carsLayer, tooltip });

      map.addInteraction(hover);
    }

    setMap(map);
  }, []);

  useEffect(() => {
    if (map && routes.length) {
      const routesLayer = findLayer(
        map.getLayers().getArray() as VectorLayer<VectorSource<Geometry>>[],
        "ROUTES"
      );

      if (routesLayer) {
        const source = routesLayer.getSource();
        manageFeatures(routes, source);

        const size = map.getSize();

        if (!isMapInitialized && size)
          centerMapOnExtent(routesLayer, map.getView(), size, () =>
            setMapAsInitialized(true)
          );
      }
    }
  }, [map, routes]);

  useEffect(() => {
    if (map && measurements.length) {
      const carsLayer = findLayer(
        map.getLayers().getArray() as VectorLayer<VectorSource<Geometry>>[],
        "CARS"
      );

      const measurementsAsLayerItems =
        queryMeasurementsAsLayerItems(measurements);

      if (carsLayer) {
        const source = carsLayer.getSource();

        measurementsAsLayerItems.forEach((item) => {
          const existingFeature = source
            .getFeatures()
            .find((feature) => feature.getId() === item.id);

          if (existingFeature) {
            const existingFeatureGeometry =
              existingFeature.getGeometry() as Point;

            const areCoordinatesEqual: boolean = checkCoordinatesEquality(
              existingFeatureGeometry.getCoordinates(),
              item.geometry.coordinates
            );

            if (!areCoordinatesEqual) {
              existingFeatureGeometry.setCoordinates(item.geometry.coordinates);
              animatePosition(existingFeature, carsLayer, map);
            }
          } else {
            const featureToAdd = createFeature(item);

            source.addFeature(featureToAdd);
            animatePosition(featureToAdd, carsLayer, map);
          }
        });
      }
    }
  }, [map, measurements]);

  useEffect(() => {
    if (selectedCoordinates && map)
      centerMapOnPoint(selectedCoordinates, map.getView());
  }, [selectedCoordinates]);

  return (
    <Styled.Container>
      <Styled.OLMap id="map" />

      <RoutesPicker />

      <Styled.TooltipContainer id="popup-content" ref={tooltipRef} />
    </Styled.Container>
  );
};

export default MapComponent;
