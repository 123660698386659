import { gql } from "@apollo/client";

export const GET_MEASUREMENTS = gql`
  query QueryOperators($UUID_row: String!) {
    listMeasurementsQuery(UUID_row: $UUID_row) {
      items {
        UUID_row
        time
        package_name
        km
        date_to
        date_from
      }
    }
  }
`;

export const ADD_MEASUREMENT = gql`
  mutation CreateMeasurement($input: CreateMeasurementsInput!) {
    createMeasurements(input: $input) {
      UUID_row
    }
  }
`;

export const DELETE_MEASUREMENT = gql`
  mutation DeleteMeasurements($input: DeleteMeasurementsInput!) {
    deleteMeasurements(input: $input) {
      time
    }
  }
`;
