import React, { FC, useEffect, useRef, useState } from "react";
import { useSubscription } from "@apollo/client";

import { useTimetable } from "./timetablesContext";
import { MEASUREMENT_SUBSCRIPTION } from "../graphQL/measurements";

interface IProps {
  measurements: Measurement[];
  removeMeasurement: (id: number) => void;
}

const MeasurementsContext = React.createContext<IProps>({} as IProps);

const MeasurementsProvider: FC = (props) => {
  const [measurements, setMeasurements] = useState<Measurement[]>([]);
  const [inactiveMeasurement, setInactiveMeasurement] = useState<Measurement>();
  const { timetables } = useTimetable();
  const { data } = useSubscription(MEASUREMENT_SUBSCRIPTION);
  const timeouts = useRef<NodeJS.Timeout[]>([]);

  useEffect(() => {
    if (data) {
      const { onCreateTracking } = data;
      const measurementPayload = collectMeasurementPayload(onCreateTracking);

      if (measurementPayload) {
        const { UUID_row } = measurementPayload;

        if (timeouts.current[UUID_row])
          clearTimeout(timeouts.current[UUID_row]);

        timeouts.current[UUID_row] = setTimeout(() => {
          setInactiveMeasurement(measurementPayload);
        }, 20000);

        if (
          measurements.find(
            (item) => item.UUID_row === onCreateTracking.UUID_row
          )
        ) {
          replaceMeasurement(measurementPayload);
        } else {
          addMeasurement(measurementPayload);
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (inactiveMeasurement) {
      const mappedMeasurement = {
        ...inactiveMeasurement,
        isActive: false,
      };

      replaceMeasurement(mappedMeasurement);
    }
  }, [inactiveMeasurement]);

  const removeMeasurement = (id: number) => {
    const filteredMeasurements = measurements.filter(
      (item) => item.UUID_row !== id
    );

    setMeasurements(filteredMeasurements);
  };

  const replaceMeasurement = (measurement: Measurement) => {
    const tempMeasurements = [...measurements];

    const index = tempMeasurements.findIndex(
      (item) => item.UUID_row === measurement.UUID_row
    );

    tempMeasurements[index] = measurement;
    setMeasurements(tempMeasurements);
  };

  const addMeasurement = (measurement: Measurement) =>
    setMeasurements((prevMeasurements) => [...prevMeasurements, measurement]);

  const collectMeasurementPayload = (tracking: MeasurementResponse) => {
    if (timetables) {
      const matchingTimetableItem = timetables.find(
        (item) => item.id === Number(tracking.timetable)
      );

      return {
        ...tracking,
        ...(matchingTimetableItem && {
          car: matchingTimetableItem.car,
          driver: matchingTimetableItem.driver,
          route: matchingTimetableItem.route,
        }),
        isActive: true,
      };
    }
  };

  const value = {
    measurements,
    removeMeasurement,
  };

  return <MeasurementsContext.Provider value={value} {...props} />;
};

const useMeasurements = () => {
  const context = React.useContext(MeasurementsContext);

  if (context === undefined) {
    throw new Error(`useMeasurements must be used within a CarsProvider`);
  }

  return context;
};

export { MeasurementsProvider, useMeasurements };
