import { View } from "ol";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Geometry } from "ol/geom";

const centerMapOnPoint = (coordinates: number[], view: View) => {
  view.animate({
    center: coordinates,
    duration: 1000,
  });
};

const centerMapOnExtent = (
  routesLayer: VectorLayer<VectorSource<Geometry>>,
  view: View,
  size: number[],
  callback: () => void
) => {
  const extent = routesLayer.getSource().getExtent();

  view.fit(extent, {
    size,
    padding: [100, 100, 100, 100],
  });

  callback();
};

const queryMeasurementsAsLayerItems = (measurements: Measurement[]) =>
  measurements.map((item) => ({
    id: item.UUID_row,
    layer_name: "CARS",
    layer_color: item.route ? item.route.layer_color : "#757575",
    geometry: {
      type: "Point",
      coordinates: [item.gps_coordinates_X, item.gps_coordinates_Y],
    },
  }));

const checkCoordinatesEquality = (
  firstCoordinates: number[],
  secondCoordinates: number[]
): boolean =>
  firstCoordinates[0] === secondCoordinates[0] &&
  firstCoordinates[1] === secondCoordinates[1];

export {
  centerMapOnPoint,
  centerMapOnExtent,
  queryMeasurementsAsLayerItems,
  checkCoordinatesEquality,
};
