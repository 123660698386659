import React, { useMemo, useState } from "react";
import Columns from "./columns/columns";
import { RoutesModal } from "./RoutesModal/RoutesModal";
import * as Styled from "./RoutesView.styles";
import { useRoutes } from "../../context/routesContext";

import { filterItem } from "../../helpers/filterTable";

import { Table } from "../../assets/style/Table.styles";
import { Button, PlusIcon } from "../../assets/style/AddButton.styles";
import { Header, SearchInput } from "../../assets/style/SearchInput.styles";
import { SearchOutlined } from "@ant-design/icons";
import { useTimetable } from "../../context/timetablesContext";
import { openNotificationWithIcon } from "../common/Notification/Notification";

const RoutesView = () => {
  const { loading, routes, addRoute, modifyRoute, removeRoute } = useRoutes();

  const [visibleModal, setVisibleModal] = useState(false);
  const [editedRoute, setEditedRoute] = useState<LayerItem>();
  const [searchValue, setSearchValue] = useState("");

  const { timetables } = useTimetable();

  const deleteRoute = (id: number) => {
    const result = timetables.filter(({ route }) => route.id === id);

    result.length
      ? openNotificationWithIcon(
          "Błąd podczas usuwania trasy.",
          "Usuń trasę z harmonogramu.",
          "error"
        )
      : removeRoute(id);
  };

  const filteredData = useMemo(
    () =>
      routes && routes.length
        ? routes.filter(({ id, common_name, layer_name }) =>
            filterItem({ id, common_name, layer_name }, searchValue)
          )
        : [],
    [routes, searchValue]
  );

  const editRoute = (id: number) => {
    const selectedRoute = routes.find((route) => route.id === id);

    if (selectedRoute) {
      setEditedRoute(selectedRoute);
      setVisibleModal(true);
    }
  };

  const saveRoute = (route: LayerItem) => {
    const { id } = route;

    id ? modifyRoute(route) : addRoute(route);
  };

  const closeModal = () => {
    if (editedRoute) setEditedRoute(undefined);
    setVisibleModal(false);
  };

  return (
    <>
      {loading ? (
        <Styled.Loading size={"large"} />
      ) : (
        <Styled.Container>
          <Header>
            <SearchInput
              prefix={<SearchOutlined />}
              title="searchInput"
              placeholder="Szukaj..."
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <Button icon={<PlusIcon />} onClick={() => setVisibleModal(true)}>
              Dodaj trasę
            </Button>
          </Header>
          <Table
            rowKey={(record: LayerItem) => record.id}
            dataSource={filteredData}
            columns={Columns({ deleteRoute, editRoute })}
            locale={{ emptyText: "Brak danych." }}
            pagination={{ pageSize: 10 }}
            scroll={{ y: "calc(100vh - 300px)" }}
          />

          <RoutesModal
            visible={visibleModal}
            route={editedRoute}
            closeModal={closeModal}
            saveRoute={saveRoute}
          />
        </Styled.Container>
      )}
    </>
  );
};

export default RoutesView;
