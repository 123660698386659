import React, { FC, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_DRIVER,
  DELETE_DRIVER,
  GET_DRIVER,
  UPDATE_DRIVER,
} from "../graphQL/drivers";

import { message } from "antd";
import { openNotificationWithIcon } from "../components/common/Notification/Notification";

interface IProps {
  loading: boolean;
  driversList: Driver[];
  addDriver: (driver: Driver) => void;
  removeDriver: (id: number) => void;
  modifyDriver: (driver: Driver) => void;
}

const DriverContext = React.createContext<IProps>({} as IProps);

const DriverProvider: FC = (props) => {
  const { data, loading } = useQuery(GET_DRIVER, { fetchPolicy: "no-cache" });
  const [createDriver] = useMutation(CREATE_DRIVER);
  const [deleteDriver] = useMutation(DELETE_DRIVER);
  const [updateDriver] = useMutation(UPDATE_DRIVER);

  const [driversList, setDriversList] = useState<Driver[]>([]);

  useEffect(() => {
    if (data) {
      const { listDrivers } = data;

      setDriversList(listDrivers);
    }
  }, [data]);

  const addDriver = (driverPayload: Driver) => {
    createDriver({
      variables: { input: driverPayload },
    })
      .then(({ data }) => {
        const newDriver = {
          ...driverPayload,
          id: data.createDriver.id,
        };

        setDriversList([...driversList, newDriver]);
      })
      .catch((error) => {
        throw error;
      });
  };

  const removeDriver = (id: number) => {
    deleteDriver({
      variables: { input: { id } },
    })
      .then(() => {
        const result = driversList.filter(
          ({ id: driverId }) => driverId !== id
        );

        setDriversList(result);
        openNotificationWithIcon(
          "Kierowca został pomyślnie usunięty.",
          "",
          "success"
        );
      })
      .catch(() => {
        message.error("Nie można usunąć wybranego kierowcy.");
      });
  };

  const modifyDriver = (driverPayload: Driver) => {
    updateDriver({ variables: { input: driverPayload } })
      .then(() => {
        const { id } = driverPayload;
        const drivers = [...driversList];

        const indexDriver = drivers.findIndex(
          ({ id: driverId }) => driverId === id
        );

        if (indexDriver !== -1) {
          drivers.splice(indexDriver, 1, driverPayload);

          setDriversList(drivers);
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  const value = {
    loading,
    driversList,
    addDriver,
    removeDriver,
    modifyDriver,
  };

  return <DriverContext.Provider value={value} {...props} />;
};

const useDriver = () => {
  const context = React.useContext(DriverContext);

  if (context === undefined) {
    throw new Error(`useDriver must be used within a DriverProvider`);
  }

  return context;
};

export { useDriver, DriverProvider };
