import React, { useMemo, useState } from "react";
import { ModalModification } from "../common/ModalModification/ModalModification";
import { useDriver } from "../../context/driversContext";
import { useTimetable } from "../../context/timetablesContext";
import Columns from "./columns/columns";

import { openNotificationWithIcon } from "../common/Notification/Notification";

import * as Styled from "./DriversView.styles";
import { Table } from "../../assets/style/Table.styles";
import { Button, PlusIcon } from "../../assets/style/AddButton.styles";
import { Header, SearchInput } from "../../assets/style/SearchInput.styles";
import { filterItem } from "../../helpers/filterTable";

import { SearchOutlined } from "@ant-design/icons";

const DriversView = () => {
  const { loading, driversList, addDriver, removeDriver, modifyDriver } =
    useDriver();

  const { timetables } = useTimetable();

  const [visibleModal, setVisibleModal] = useState(false);
  const [editedDriver, setEditedDriver] = useState<Driver>();
  const [searchValue, setSearchValue] = useState("");

  const editDriver = (driverId: number) => {
    const selectedDriver = driversList.find(({ id }) => id === driverId);

    if (selectedDriver) {
      setEditedDriver(selectedDriver);
      setVisibleModal(true);
    }
  };

  const deleteDriver = (id: number) => {
    const result = timetables.filter(({ driver }) => driver.id === id);

    result.length
      ? openNotificationWithIcon(
          "Błąd podczas usuwania kierowcy.",
          "Usuń kierowcę z harmonogramu.",
          "error"
        )
      : removeDriver(id);
  };

  const closeModal = () => {
    if (editedDriver) setEditedDriver(undefined);

    setVisibleModal(false);
  };

  const saveDriver = (driver: Driver) => {
    const { id } = driver;

    id ? modifyDriver(driver) : addDriver(driver);
  };

  const filteredCars = useMemo(
    () =>
      driversList && driversList.length
        ? driversList
            .map(({ id, surname, telephone, name }: Driver) => ({
              id,
              surname_name: `${surname} ${name} `,
              telephone,
            }))
            .filter(({ surname_name, telephone }) =>
              filterItem({ surname_name, telephone }, searchValue)
            )
        : [],
    [driversList, searchValue]
  );

  return (
    <>
      {loading ? (
        <Styled.Loading size={"large"} />
      ) : (
        <>
          <Styled.Container>
            <Header>
              <SearchInput
                prefix={<SearchOutlined />}
                placeholder="Szukaj..."
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <Button onClick={() => setVisibleModal(true)} icon={<PlusIcon />}>
                Dodaj kierowcę
              </Button>
            </Header>

            <Table
              rowKey={(record: Driver) => record.id}
              dataSource={filteredCars}
              columns={Columns({ editDriver, deleteDriver })}
              locale={{ emptyText: "Brak danych." }}
              pagination={{
                showSizeChanger: true,
                pageSizeOptions: [100, 200, 300, 400, 500],
                defaultPageSize: 100,
              }}
              scroll={{ y: "calc(100vh - 300px)" }}
            />
          </Styled.Container>

          <ModalModification
            visible={visibleModal}
            onClose={closeModal}
            onSave={saveDriver}
            user={editedDriver}
          />
        </>
      )}
    </>
  );
};

export default DriversView;
