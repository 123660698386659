import React, { useEffect, useMemo, useState } from "react";
import * as Styled from "./MeasuringPackages.styles";
import Columns from "./columns";

import { useCars } from "../../context/carsContext";
import { useMeasuringPackages } from "../../context/measuringPackagesContext";

import moment from "moment";
import { filterItem } from "../../helpers/filterTable";

import { Table } from "../../assets/style/Table.styles";
import { SearchOutlined } from "@ant-design/icons";
import { Select } from "antd";
import { SelectValue } from "antd/es/select";
import { DATE_FORMAT } from "../../constants/dateFormat";

const { Option } = Select;

const MeasuringPackages = () => {
  const { measurementPackagesList, changePackageStatus, queryDataByDevice } =
    useMeasuringPackages();
  const { cars } = useCars();

  const [dataTable, setDataTable] = useState<MeasuringPackage[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [measurementDay, setMeasurementDay] = useState("");
  const [selectedMeasuringDevice, setSelectedMeasuringDevice] =
    useState<string>();

  useEffect(() => {
    if (measurementPackagesList) {
      const data = measurementPackagesList.map(
        ({ date_to, date_from, ...item }) => ({
          ...item,
          date_to: moment(Number(date_to)).format(DATE_FORMAT),
          date_from: moment(Number(date_from)).format(DATE_FORMAT),
        })
      );
      setDataTable(data);
    }
  }, [measurementPackagesList]);

  useEffect(() => {
    queryDataByDevice(selectedMeasuringDevice);
  }, [selectedMeasuringDevice]);

  const changeMeasuringDevice = (id: SelectValue | undefined) => {
    if (id && typeof id === "string") {
      setSelectedMeasuringDevice(id);
    } else setSelectedMeasuringDevice("");
  };

  const filterData = useMemo(
    () =>
      dataTable
        .filter((item) => filterItem(item, searchValue))
        .filter((item) => filterItem(item, measurementDay)),
    [dataTable, searchValue, measurementDay]
  );

  const acceptPackage = (item: MeasuringPackage) =>
    changePackageStatus(item, "accepted");

  const denyPackage = (item: MeasuringPackage) =>
    changePackageStatus(item, "denied");

  return (
    <Styled.Container>
      <Styled.FilterContainer>
        <Styled.Select
          placeholder={"Wybierz urządzenie"}
          onChange={changeMeasuringDevice}
          allowClear={true}
          data-testid="select-device"
        >
          {cars && cars.length
            ? cars.map(({ id, car_name }) => (
                <Option key={id} value={id}>
                  {car_name}
                </Option>
              ))
            : null}
        </Styled.Select>
        <Styled.DatePicker
          data-testid="date-picker"
          onChange={(_date, dateString) => setMeasurementDay(dateString)}
        />
        <Styled.SearchInput
          data-testid="search-input"
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder={"Szukaj..."}
          prefix={<SearchOutlined />}
          title="searchInput"
        />
      </Styled.FilterContainer>

      <Table
        rowKey={(record: MeasuringPackage) => record.time}
        dataSource={filterData}
        columns={Columns(acceptPackage, denyPackage)}
        locale={{
          emptyText: selectedMeasuringDevice
            ? "Brak danych."
            : "Brak wybranego urządzenia",
        }}
        pagination={{ pageSize: 10 }}
        scroll={{ y: "calc(100vh - 300px)" }}
      />
    </Styled.Container>
  );
};

export default MeasuringPackages;
