import styled from "styled-components";
import { Badge as AntBadge, Card as AntdCard } from "antd";
import stopwatch from "../../assets/icons/stopwatch.svg";

interface LineProps {
  color: string;
}

interface CircleProps {
  color: string;
}

interface StatusProps {
  color?: string;
}

export const Line = styled.div<LineProps>`
  background-color: ${(props) => props.color};
  width: 150px;
  height: 5px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const ContainerHeader = styled.div`
  display: flex;
  width: 100%;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Header = styled.div`
  height: 17px;
  margin-top: 5px;
  font-weight: bold;
  color: #383838;
`;

export const OperatorHeader = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: #383838;
  margin-top: 6px;
`;

export const PhoneNumber = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
`;

export const RouteHeader = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: #383838;
  margin-bottom: 10px;
`;

export const Stopwatch = styled.div`
  background-image: url(${stopwatch});
  height: 64px;
  width: 64px;
  align-self: center;
  margin: 10px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  bottom: 0;
`;

export const InfoContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AlertContainer = styled.div`
  display: flex;
  align-items: end;
`;

export const ConnectionStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  margin-top: 8px;
  margin-right: 12px;
`;

interface RemoveSetButtonProps {
  onClick: (e: MouseEvent) => void;
}

export const RemoveSetButton = styled.div<RemoveSetButtonProps>`
  border: 1px transparent solid;
  padding: 0 5px;
  margin-right: 5px;
  color: #888888;

  :hover {
    transition: 0.5s;
    border: 1px #383838 solid;
    color: #383838;
  }
`;

export const ConnectionStatus = styled.div<CircleProps>`
  width: 16px;
  height: 16px;
  background: ${(props) => props.color};
  border-radius: 50%;
`;

export const ConnectionAlert = styled.div`
  width: 150px;
  text-align: center;
  font-size: 14px;
  padding: 5px;
`;

export const CardContainer = styled.div`
  width: 100%;
  background-color: #fff;
  border: 1px #e7e7e7 solid;
  border-radius: 20px;
  margin-bottom: 10px;
`;

export const Card = styled(AntdCard).attrs({
  "data-testid": "card",
})<StatusProps>`
  .ant-card-body {
    padding: 0;
  }
  background: #ffffff;
  border-radius: 22px;
  margin-bottom: 10px;

  border-color: ${(props) => props.color};
  border-width: 2px;

  :hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
`;

export const ContainerLine = styled.div`
  display: flex;
  justify-content: center;
`;

export const MeasuringSetContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const MeasuringSetContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
`;

export const PackagesContainer = styled.div`
  margin: 10px;
  display: flex;
  flex: 1;
`;

export const PackagesContentContainer = styled.div`
  border-radius: 10px;
  background-color: rgba(255, 0, 0, 0.25);
  color: #383838;
  padding: 10px;
`;

export const Badge = styled(AntBadge)`
  width: 100%;
`;

export const PhotosButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 17px;

  :hover {
    cursor: pointer;
    color: #969696;
  }
`;

export const PhotosButtonTitle = styled.div`
  margin-right: 5px;
`;
