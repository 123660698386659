export const getCookie = (name: string) => {
  const cookieName = `${name}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookies = decodedCookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    let current = cookies[i];

    while (current.charAt(0) === " ") {
      current = current.substring(1);
    }

    if (current.indexOf(cookieName) === 0) {
      return current.substring(cookieName.length, current.length);
    }
  }
  return null;
};

export const deleteAllCookies = () => {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const position = cookie.indexOf("=");
    const name = position > -1 ? cookie.substr(0, position) : cookie;

    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
  }
};
