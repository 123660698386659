import { gql } from "@apollo/client";

export const GET_DRIVER = gql`
  query QueryDrivers {
    listDrivers {
      id
      name
      surname
      telephone
    }
  }
`;

export const CREATE_DRIVER = gql`
  mutation CreateDriver($input: CreateDriverInput!) {
    createDriver(input: $input) {
      id
    }
  }
`;

export const DELETE_DRIVER = gql`
  mutation DeleteDriver($input: DeleteDriverInput!) {
    deleteDriver(input: $input) {
      id
    }
  }
`;

export const UPDATE_DRIVER = gql`
  mutation UpdateDriver($input: UpdateDriverInput!) {
    updateDriver(input: $input) {
      id
    }
  }
`;
