const KEYS = {
  USER_DISABLED: "User is disabled",
  NOT_CONFIRMED: "User is not confirmed",
  NOT_EXISTS: "User does not exist",
  BAD_CREDENTIALS: "Incorrect username or password",
};

const MESSAGES = {
  USER_DISABLED: "Konto użytkownika jest nieaktywne",
  NOT_CONFIRMED: "Konto użytkownika nie zostało potwierdzone",
  BAD_CREDENTIALS: "Błędny login i/lub hasło",
};

const POLICIES = {
  CHECK_USER_DISABLED: (error: string) => error.includes(KEYS.USER_DISABLED),
  CHECK_CREDENTIALS: (error: string) => error.includes(KEYS.BAD_CREDENTIALS),
  CHECK_USER_CONFIRMATION: (error: string) =>
    error.includes(KEYS.NOT_CONFIRMED),
  CHECK_USER_EXISTS: (error: string) => error.includes(KEYS.NOT_EXISTS),
};

const AUTH_CONSTANTS = {
  KEYS,
  MESSAGES,
  POLICIES,
};

export default AUTH_CONSTANTS;
