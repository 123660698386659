import { gql } from "@apollo/client";

const GET_TIMETABLES = gql`
  query QueryTimetables {
    listTimetablefull {
      id
      from_time
      to_time
      car {
        car_name
        id
      }
      driver {
        id
        name
        surname
        telephone
      }
      operator {
        id
        name
        surname
        telephone
      }
      project {
        id
        project_name
      }
      route {
        common_name
        id
        layer_color
        layer_name
        shp_filename
      }
    }
  }
`;

const CREATE_TIMETABLE = gql`
  mutation CreateTimetable($input: CreateTimetableInput!) {
    createTimetable(input: $input) {
      id
    }
  }
`;

const DELETE_TIMETABLE = gql`
  mutation DeleteTimetable($input: DeleteTimetableInput!) {
    deleteTimetable(input: $input) {
      id
    }
  }
`;

const UPDATE_TIMETABLE = gql`
  mutation UpdateTimetable($input: UpdateTimetableInput!) {
    updateTimetable(input: $input) {
      id
    }
  }
`;

export { GET_TIMETABLES, DELETE_TIMETABLE, UPDATE_TIMETABLE, CREATE_TIMETABLE };
