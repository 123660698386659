import React, { FC, useState } from "react";
import { useApolloClient, useMutation } from "@apollo/client";
import {
  ADD_MEASUREMENT,
  DELETE_MEASUREMENT,
  GET_MEASUREMENTS,
} from "../graphQL/measuringPackages";
import { message } from "antd";

interface IProps {
  measurementPackagesList: MeasuringPackage[];
  changePackageStatus: (
    measuringPackage: MeasuringPackage,
    status: string
  ) => void;
  queryDataByDevice: (UUID_row: string | undefined) => void;
}

const MeasuringPackagesContext = React.createContext<IProps>({} as IProps);

const MeasuringPackagesProvider: FC = (props) => {
  const client = useApolloClient();

  const [deletePackage] = useMutation(DELETE_MEASUREMENT);
  const [addPackage] = useMutation(ADD_MEASUREMENT);

  const [measurementPackagesList, setMeasurementPackagesList] = useState<
    MeasuringPackage[]
  >([]);

  const changePackageStatus = (item: MeasuringPackage, status: string) => {
    const { time, UUID_row } = item;

    deletePackage({
      variables: { input: { time, UUID_row } },
    })
      .then(() => {
        const mappedItem = getMappedItemToAdd(item, status);

        addPackage({
          variables: { input: mappedItem },
        })
          .then(() => {
            const tempPackages = measurementPackagesList.filter(
              (item) => item.time !== time
            );

            setMeasurementPackagesList(tempPackages);
          })
          .catch(() => {
            message.error("Nie można dodać wybranej paczki.");
          });
      })
      .catch(() => {
        message.error("Nie można usunąć wybranej paczki.");
      });
  };

  const getMappedItemToAdd = (item: MeasuringPackage, status: string) => {
    const mappedId = item.UUID_row.split("#")[0];

    return {
      ...item,
      UUID_row: `${mappedId}#${status}`,
    };
  };

  const queryDataByDevice = async (UUID_row: string | undefined) => {
    if (UUID_row) {
      const mappedId = `${UUID_row}#active`;

      await client
        .query({
          query: GET_MEASUREMENTS,
          variables: {
            UUID_row: mappedId,
          },
          fetchPolicy: "no-cache",
        })
        .then(({ data }) =>
          setMeasurementPackagesList(
            data.listMeasurementsQuery ? data.listMeasurementsQuery.items : []
          )
        )
        .catch(() => console.log("błąd odczytu danych paczek pomiarowych"));
    } else setMeasurementPackagesList([]);
  };

  const value = {
    measurementPackagesList,
    changePackageStatus,
    queryDataByDevice,
  };

  return <MeasuringPackagesContext.Provider value={value} {...props} />;
};

const useMeasuringPackages = () => {
  const context = React.useContext(MeasuringPackagesContext);

  if (context === undefined) {
    throw new Error(
      `useMeasuringPackages must be used within a MeasuringPackagesProvider`
    );
  }

  return context;
};

export { useMeasuringPackages, MeasuringPackagesProvider };
