import React from "react";
import * as Styled from "./columns.styles";
import { ColumnsType } from "antd/es/table";
import { Button, Popconfirm } from "antd";
import { compare } from "natural-orderby";

interface IProps {
  deleteRoute: (id: number) => void;
  editRoute: (id: number) => void;
}

const Columns = ({
  deleteRoute,
  editRoute,
}: IProps): ColumnsType<LayerItem> => [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    filterMultiple: false,
    width: "5%",
    sorter: (a, b) => compare()(a.id, b.id),
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "Nazwa",
    dataIndex: "common_name",
    key: "common_name",
    filterMultiple: false,
    width: "20%",
    sorter: (a, b) => compare()(a.common_name, b.common_name),
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "Nazwa warstwy",
    dataIndex: "layer_name",
    key: "layer_name",
    filterMultiple: false,
    width: "20%",
    sorter: (a, b) => compare()(a.layer_name, b.layer_name),
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "Kolor warstwy",
    dataIndex: "layer_color",
    key: "layer_color",
    filterMultiple: false,
    width: "10%",
    sorter: (a, b) => compare()(a.layer_color, b.layer_color),
    render: (_, { layer_color }) => <Styled.LayerColor color={layer_color} />,
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "Nazwa pliku SHP",
    dataIndex: "shp_filename",
    key: "shp_filename",
    filterMultiple: false,
    sorter: (a, b) => compare()(a.shp_filename, b.shp_filename),
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "",
    dataIndex: "edit",
    width: 60,
    key: "edit",
    render: (_, { id }) => (
      <Button
        type={"link"}
        icon={<Styled.EditIcon />}
        onClick={() => editRoute(id)}
      />
    ),
  },
  {
    title: "",
    dataIndex: "delete",
    width: 60,
    key: "delete",
    render: (_, { id }) => (
      <Popconfirm
        title="Czy napewno usunąć?"
        onConfirm={() => deleteRoute(id)}
        okText="Usuń"
        cancelText="Anuluj"
        placement={"topRight"}
      >
        <Button type={"link"} icon={<Styled.TrashIcon />} />
      </Popconfirm>
    ),
  },
];

export default Columns;
