import React from "react";
import ReactDOM from "react-dom";
import { ConfigProvider } from "antd";
import plPL from "antd/es/locale/pl_PL";
import "antd/dist/antd.css";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Montserrat } from "./assets/fonts/fontsFile";

ReactDOM.render(
  <React.StrictMode>
    <Montserrat />
    <ConfigProvider locale={plPL}>
      <App />
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
