import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: auto 450px;
  height: 100%;
`;

export const MapContainer = styled.div`
  overflow: hidden;
  display: flex;
  flex: 1;
  border-radius: 60px;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const MeasurementContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
`;
