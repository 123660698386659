// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_appsync_graphqlEndpoint:
    "https://jlqn3q4cp5cbpcs57hzu2mmgpy.appsync-api.eu-central-1.amazonaws.com/graphql",
  aws_appsync_region: "eu-west-1",
  aws_appsync_authenticationType: "API_KEY",
  aws_appsync_apiKey: "da2-ijsg442umjhqvn7poaifa4wnke",
};

export default awsmobile;
