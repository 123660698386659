import styled from "styled-components";
import { Layout } from "antd";

export const Container = styled.div`
  display: grid;
  grid-template-rows: 45px calc(100% - 65px);
  grid-gap: 20px;
  height: 100%;
  padding: 20px;
  background-color: #fafafa;
`;

export const LayoutContent = styled(Layout.Content)`
  display: flex;
  flex: 1;
`;
