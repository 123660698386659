import React, { useMemo, useState } from "react";

import { useTimetable } from "../../context/timetablesContext";
import TimetableManagementModal from "./TimetableManagementModal/TimetableManagementModal";

import Columns from "./columns";
import { filterItem } from "../../helpers/filterTable";

import { Table } from "../../assets/style/Table.styles";
import * as Styled from "./TimetableManagementView.styles";
import { Button, PlusIcon } from "../../assets/style/AddButton.styles";
import { Header, SearchInput } from "../../assets/style/SearchInput.styles";
import { SearchOutlined } from "@ant-design/icons";

const TimetableManagementView = () => {
  const [isVisible, setVisible] = useState(false);
  const [editedTimetable, setEditedTimetable] = useState<Timetable>();
  const [searchValue, setSearchValue] = useState("");

  const {
    timetables,
    loading,
    createTimetable,
    deleteTimetable,
    updateTimetable,
  } = useTimetable();

  const closeModal = () => {
    setEditedTimetable(undefined);
    setVisible(false);
  };

  const saveTimetable = (
    newTimetableData: NewTimetableData,
    timetableId?: number
  ) => {
    if (newTimetableData && timetableId) {
      updateTimetable(newTimetableData, timetableId);
    } else if (newTimetableData) createTimetable(newTimetableData);
  };

  const editTimetable = (timetableId: number) => {
    const selectedTimetable = timetables.find(({ id }) => id === timetableId);

    setEditedTimetable(selectedTimetable);
    setVisible(true);
  };

  const filteredTimetables = useMemo(
    () =>
      timetables
        .map(({ operator, driver, car, route, to_time, from_time, id }) => ({
          id,
          operator_name: `${operator.name} ${operator.surname}`,
          driver_name: `${driver.name} ${driver.surname}`,
          car_name: car.car_name,
          route_name: route.common_name,
          time: `${from_time} ${to_time}`,
        }))
        .filter(({ operator_name, driver_name, car_name, route_name, time }) =>
          filterItem(
            { operator_name, driver_name, car_name, route_name, time },
            searchValue
          )
        ),
    [timetables, searchValue]
  );

  return (
    <>
      {loading ? (
        <Styled.Loading size={"large"} />
      ) : (
        <>
          <Styled.Container>
            <Header>
              <SearchInput
                prefix={<SearchOutlined />}
                placeholder="Szukaj..."
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <Button onClick={() => setVisible(true)} icon={<PlusIcon />}>
                Dodaj harmonogram
              </Button>
            </Header>
            <Table
              rowKey={(record: Timetable) => record.id}
              dataSource={filteredTimetables}
              columns={Columns({ deleteTimetable, editTimetable })}
              locale={{ emptyText: "Brak danych." }}
              pagination={{
                pageSizeOptions: [100, 200, 300, 400, 500],
                defaultPageSize: 100,
              }}
              scroll={{ y: "calc(100vh - 300px)" }}
            />
          </Styled.Container>
          <TimetableManagementModal
            timetable={editedTimetable}
            visible={isVisible}
            onClose={closeModal}
            saveTimetable={saveTimetable}
          />
        </>
      )}
    </>
  );
};

export default TimetableManagementView;
