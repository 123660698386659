import React, { useMemo, useState } from "react";
import { useCars } from "../../context/carsContext";
import { useTimetable } from "../../context/timetablesContext";

import { Container, Loading } from "./CarsManagement.styles";
import * as Styled from "../../assets/style/Table.styles";
import { Button, PlusIcon } from "../../assets/style/AddButton.styles";
import { Header, SearchInput } from "../../assets/style/SearchInput.styles";
import { SearchOutlined } from "@ant-design/icons";

import Columns from "./columns";
import CarManagementModal from "./CarManagmentModal/CarManagementModal";
import { openNotificationWithIcon } from "../common/Notification/Notification";

import { filterItem } from "../../helpers/filterTable";

const CarsManagement = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [editedCar, setEditedCar] = useState<Car>();
  const [searchValue, setSearchValue] = useState("");

  const { cars, createCar, deleteCar, updateCar, loading } = useCars();
  const { timetables } = useTimetable();

  const findCarById = (carId: string) =>
    cars.find(({ id }) => id.toLowerCase().includes(carId.toLowerCase()));

  const onEditCar = (carId: string) => {
    const selectedCar = findCarById(carId);

    if (selectedCar) {
      setEditedCar(selectedCar);
      setModalVisible(true);
    }
  };

  const closeModal = () => {
    setEditedCar(undefined);
    setModalVisible(false);
  };

  const onSaveCar = (payload: Car) => {
    const { id } = payload;

    if (id) {
      const car = findCarById(id);

      if (car) {
        updateCar(payload);
      } else createCar(payload);
    }
  };

  const onDeleteCar = (carId: string) => {
    const foundCar = timetables.find(({ car: { id } }) => id === carId);

    foundCar
      ? openNotificationWithIcon(
          "Błąd podczas usuwania samochodu",
          "Usuń samochód z harmonogramu",
          "info"
        )
      : deleteCar(carId);
  };

  const filteredCars = useMemo(
    () => cars.filter((car) => filterItem(car, searchValue)),
    [cars, searchValue]
  );

  return (
    <>
      {loading ? (
        <Loading size={"large"} />
      ) : (
        <>
          <Container>
            <Header>
              <SearchInput
                prefix={<SearchOutlined />}
                placeholder="Szukaj..."
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <Button onClick={() => setModalVisible(true)} icon={<PlusIcon />}>
                Dodaj samochód
              </Button>
            </Header>

            <Styled.Table
              rowKey={(car: Car) => car.id}
              dataSource={filteredCars}
              columns={Columns({ onDeleteCar, onEditCar })}
              locale={{ emptyText: "Brak danych." }}
              pagination={{ pageSize: 10 }}
              scroll={{ y: "calc(100vh - 300px)" }}
            />
          </Container>
          <CarManagementModal
            editedCar={editedCar}
            visible={modalVisible}
            onClose={closeModal}
            onSaveCar={onSaveCar}
          />
        </>
      )}
    </>
  );
};

export default CarsManagement;
