import React from "react";

import { ColumnsType } from "antd/es/table";
import { Button, Popconfirm } from "antd";
import * as Styled from "../../assets/style/Table.styles";
import { compare } from "natural-orderby";

interface IProps {
  deleteTimetable: (id: number) => void;
  editTimetable: (id: number) => void;
}

const Columns = ({
  deleteTimetable,
  editTimetable,
}: IProps): ColumnsType<TimetableTable> => [
  {
    title: "Koordynator",
    dataIndex: "operator_name",
    key: "operator_name",
    filterMultiple: false,
    sorter: (a, b) => compare()(a.operator_name, b.operator_name),
  },
  {
    title: "Kierowca",
    dataIndex: "driver_name",
    key: "driver_name",
    filterMultiple: false,
    sorter: (a, b) => compare()(a.driver_name, b.driver_name),
  },
  {
    title: "Nazwa urządzenia pomiarowego",
    dataIndex: "car_name",
    key: "car_name",
    filterMultiple: false,
    sorter: (a, b) => compare()(a.car_name, b.car_name),
  },
  {
    title: "Trasa",
    dataIndex: "route_name",
    key: "route_name",
    filterMultiple: false,
    sorter: (a, b) => compare()(a.route_name, b.route_name),
  },
  {
    title: "Slot czasowy",
    dataIndex: "time",
    key: "time",
    filterMultiple: false,
    sorter: (a, b) => compare()(a.time, b.time),
  },
  {
    title: "",
    dataIndex: "edit",
    width: 60,
    key: "edit",
    render: (_, { id }) => (
      <Button
        data-testid="edit-timetable-button"
        type={"link"}
        icon={<Styled.Edit />}
        onClick={() => editTimetable(id)}
      />
    ),
  },
  {
    title: "",
    dataIndex: "delete",
    width: 60,
    key: "delete",
    render: (_, { id }) => (
      <Popconfirm
        title="Czy na pewno usunąć?"
        onConfirm={() => deleteTimetable(id)}
        okText="Usuń"
        cancelText="Anuluj"
        placement={"topRight"}
      >
        <Button
          data-testid="delete-timetable-button"
          type={"link"}
          icon={<Styled.Trash />}
        />
      </Popconfirm>
    ),
  },
];

export default Columns;
