import React, { createContext, ReactElement, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { message } from "antd";
import JWTDecode from "jwt-decode";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";

import { LOGIN_ERRORS } from "../constants/login-errors";
import AUTH_CONSTANTS from "../constants/auth-messages";
import UserPool from "../helpers/UserPool";
import { deleteAllCookies, getCookie } from "../helpers/Cookies";
import { setAWSConfig } from "./helpers/AWSMethods";

interface AuthContextProps {
  user: AccessToken | null;
  isLoading: boolean;
  signIn: (credentials: Credentials) => void;
  signOut: () => void;
}

export const AuthContext = createContext<AuthContextProps>(
  {} as AuthContextProps
);
AuthContext.displayName = "AuthContext";

interface AuthProviderProps {
  children: ReactElement;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const history = useHistory();
  const [user, setUser] = useState<AccessToken | null>(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const accessTokenCookie = getCookie("access_token");
    const idTokenCookie = getCookie("id_token");

    if (accessTokenCookie && idTokenCookie) {
      const user = JWTDecode<AccessToken>(accessTokenCookie);

      setAWSConfig(idTokenCookie);

      setUser(user);
      history.push("/dashboard/map");
    } else {
      history.push("/login");
    }
  }, [history]);

  const onError = async (error?: string) => {
    const errorMessage = error || LOGIN_ERRORS.GENERIC;

    message.error(errorMessage);
  };

  const handleSignInErrors = async (error_description: string) => {
    if (AUTH_CONSTANTS.POLICIES.CHECK_USER_CONFIRMATION(error_description)) {
      message.error(AUTH_CONSTANTS.MESSAGES.NOT_CONFIRMED);
    }

    if (AUTH_CONSTANTS.POLICIES.CHECK_USER_EXISTS(error_description)) {
      message.error(AUTH_CONSTANTS.MESSAGES.BAD_CREDENTIALS);
    }

    if (AUTH_CONSTANTS.POLICIES.CHECK_USER_DISABLED(error_description)) {
      message.error(AUTH_CONSTANTS.MESSAGES.USER_DISABLED);
    }

    if (AUTH_CONSTANTS.POLICIES.CHECK_CREDENTIALS(error_description)) {
      message.error(AUTH_CONSTANTS.MESSAGES.BAD_CREDENTIALS);
    }
  };

  const signIn = async ({ username, password }: Credentials) => {
    if (username && password) {
      setLoading(true);

      const user = new CognitoUser({
        Username: username,
        Pool: UserPool,
      });

      const authDetails = new AuthenticationDetails({
        Username: username,
        Password: password,
      });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          const accessToken = data.getAccessToken();
          const expireTime = data.getIdToken().getExpiration();
          const refreshToken = data.getRefreshToken();
          const idToken = data.getIdToken().getJwtToken();

          document.cookie = `needs_refresh=false; max-age=${
            expireTime ? Math.round(expireTime / 3) : 300
          }; path=/`;
          document.cookie = `access_token=${accessToken.getJwtToken()}; max-age=${
            expireTime ? expireTime : 900
          }; path=/`;
          document.cookie = `id_token=${idToken}; max-age=${
            expireTime ? expireTime : 900
          }; path=/`;
          document.cookie = `refresh_token=${refreshToken.getToken()}; max-age=43200; path=/`;

          const user = JWTDecode<AccessToken>(accessToken.getJwtToken());
          setAWSConfig(idToken);

          setUser(user);
          setLoading(false);

          history.push("/dashboard/map");
        },
        onFailure: async ({ message }) => {
          if (message) await handleSignInErrors(message);

          setLoading(false);
        },
      });
    } else {
      await onError(LOGIN_ERRORS.EMPTY_INPUT);
    }
  };

  const signOut = () => {
    if (user) {
      const auth = new CognitoUser({
        Username: user.username,
        Pool: UserPool,
      });

      auth.signOut(() => {
        setUser(null);
        deleteAllCookies();
        history.push("/login");
      });
    }
  };

  const value = {
    user,
    isLoading,
    signIn,
    signOut,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
