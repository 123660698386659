import React, { FC, useEffect, useState } from "react";

import { useQuery, useMutation } from "@apollo/client";

import { GET_CARS, CREATE_CAR, DELETE_CAR, UPDATE_CAR } from "../graphQL/cars";

interface IProps {
  cars: Car[];
  createCar: (payload: Car) => void;
  updateCar: (payload: Car) => void;
  deleteCar: (id: string) => void;
  loading: boolean;
}

const CarsContext = React.createContext<IProps>({} as IProps);

const CarsProvider: FC = (props) => {
  const { data: dataSourceCar, loading } = useQuery(GET_CARS, {
    fetchPolicy: "no-cache",
  });
  const [createCarGql] = useMutation(CREATE_CAR);
  const [updateCarGql] = useMutation(UPDATE_CAR);
  const [deleteCarGql] = useMutation(DELETE_CAR);
  const [cars, setCars] = useState<Car[]>([]);

  useEffect(() => {
    if (dataSourceCar) {
      const { listCars } = dataSourceCar;
      setCars(listCars);
    }
  }, [dataSourceCar]);

  const createCar = (payload: Car) => {
    createCarGql({ variables: { input: payload } })
      .then(() => {
        setCars([...cars, payload]);
      })
      .catch((error) => {
        throw error;
      });
  };

  const updateCar = (payload: Car) => {
    const { id } = payload;

    if (id) {
      updateCarGql({ variables: { input: payload } })
        .then(() => {
          const carList = [...cars];
          const carIndex = carList.findIndex((car) => car.id === id);

          if (carIndex > -1) carList.splice(carIndex, 1, payload);

          setCars(carList);
        })
        .catch((error) => {
          throw error;
        });
    }
  };

  const deleteCar = (id: string) => {
    deleteCarGql({ variables: { input: { id } } })
      .then(() => {
        const newCarList = cars.filter(
          ({ id: carId }) => carId.toLowerCase() !== id.toLowerCase()
        );

        setCars(newCarList);
      })
      .catch((error) => {
        throw error;
      });
  };

  const value = {
    cars,
    createCar,
    deleteCar,
    updateCar,
    loading,
  };

  return <CarsContext.Provider value={value} {...props} />;
};

const useCars = () => {
  const context = React.useContext(CarsContext);

  if (context === undefined) {
    throw new Error(`useCars must be used within a CarsProvider`);
  }

  return context;
};

export { CarsProvider, useCars };
