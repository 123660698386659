import { gql } from "@apollo/client";

export const GET_OPERATORS = gql`
  query QueryOperators {
    listOperators {
      id
      name
      surname
      telephone
    }
  }
`;

export const ADD_OPERATOR = gql`
  mutation CreteOperator($input: CreateOperatorInput!) {
    createOperator(input: $input) {
      id
    }
  }
`;

export const DELETE_OPERATOR = gql`
  mutation DeleteOperator($input: DeleteOperatorInput!) {
    deleteOperator(input: $input) {
      id
    }
  }
`;

export const UPDATE_OPERATOR = gql`
  mutation UpdateOperator($input: UpdateOperatorInput!) {
    updateOperator(input: $input) {
      id
      name
      surname
      telephone
    }
  }
`;
