import React, { useEffect, useState } from "react";

import { formLayout } from "../../../constants/layout";
import { Form, Input, Modal } from "antd";
import { SketchPicker } from "react-color";

interface IProps {
  visible: boolean;
  closeModal: () => void;
  saveRoute: (route: LayerItem) => void;
  route?: LayerItem;
}

export const RoutesModal = ({
  visible,
  closeModal,
  saveRoute,
  route,
}: IProps) => {
  const [form] = Form.useForm();
  const [color, setColor] = useState("");

  useEffect(() => {
    if (route) {
      const { layer_color, common_name, layer_name, shp_filename } = route;
      form.setFieldsValue({
        common_name,
        layer_name,
        shp_filename,
      });

      setColor(layer_color);
    }
  }, [route]);

  const close = () => {
    form.resetFields();
    setColor("");
    closeModal();
  };

  const save = () => {
    form.validateFields().then((value) => {
      const id = route?.id;

      const payload: LayerItem = {
        ...(id && { id }),
        ...value,
        layer_color: color,
      };

      saveRoute(payload);
      close();
    });
  };

  return (
    <Modal
      title="Dodaj trase"
      visible={visible}
      onCancel={close}
      cancelText={"Anuluj"}
      okText={"Zapisz"}
      onOk={save}
      maskClosable={false}
      width={650}
    >
      <Form form={form} {...formLayout}>
        <Form.Item
          label={"Nazwa"}
          name={"common_name"}
          rules={[{ required: true, message: "Podaj nazwę." }]}
        >
          <Input placeholder={"Podaj nazwę"} />
        </Form.Item>

        <Form.Item
          label={"Nazwa warstwy"}
          name={"layer_name"}
          rules={[{ required: true, message: "Podaj nazwę warstwy." }]}
        >
          <Input placeholder={"Podaj nazwę warstwy"} />
        </Form.Item>

        <Form.Item label={"Kolor warstwy"} name={"layer_color"}>
          <SketchPicker
            color={color}
            onChange={({ hex }) => setColor(hex)}
            width={"250px"}
          />
        </Form.Item>

        <Form.Item
          label="Plik SHP: "
          name="shp_filename"
          rules={[{ required: true, message: "Podaj ścieżkę do pliku." }]}
        >
          <Input placeholder={"Podaj link do pliku SHP"} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
