import Map from "ol/Map";
import View from "ol/View";
import { defaults } from "ol/control";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import { get } from "ol/proj";
import { register } from "ol/proj/proj4";
import proj4 from "proj4";
import { initializeLayers } from "./layers";

const initializeMap = () => {
  return new Map({
    target: "map",
    view: new View({
      projection: queryProjection(),
      center: [637129.74, 487006.67],
      zoom: 8,
    }),
    layers: [
      new TileLayer({
        source: new OSM(),
      }),
      ...initializeLayers(),
    ],
    controls: defaults({
      rotate: false,
      zoom: false,
      attribution: false,
    }),
  });
};

const queryProjection = () => {
  proj4.defs(
    "EPSG:2180",
    "+proj=tmerc +lat_0=0 +lon_0=19 +k=0.9993 +x_0=500000 +y_0=-5300000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
  );
  register(proj4);
  const projection = get("EPSG:2180");
  projection.setExtent([0, 0, 1228800, 819200]);
  return projection;
};

export { initializeMap };
