import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Geometry from "ol/geom/Geometry";

const initializeLayers = () => [
  new VectorLayer({
    source: new VectorSource(),
    zIndex: 1500,
    opacity: 0.8,
    properties: {
      symbol: "ROUTES",
    },
  }),
  new VectorLayer({
    source: new VectorSource(),
    zIndex: 1501,
    properties: {
      symbol: "CARS",
    },
  }),
];

const findLayer = (
  layers: VectorLayer<VectorSource<Geometry>>[],
  symbol: string
) => {
  const layer = layers.find((item) => item.get("symbol") === symbol);

  if (layer) return layer;
};

export { initializeLayers, findLayer };
