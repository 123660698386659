import React, { useEffect, useMemo, useState } from "react";
import { DownOutlined, SearchOutlined, UpOutlined } from "@ant-design/icons";
import { compare } from "natural-orderby";

import * as Styled from "./RoutesPicker.styles";
import { useRoutes } from "../../context/routesContext";
import { SearchInput } from "../../assets/style/SearchInput.styles";
import { filterItem } from "../../helpers/filterTable";

const RoutesPicker = () => {
  const { routes, setRouteVisibility, setAllRouteVisibility } = useRoutes();
  const [indeterminate, setIndeterminate] = React.useState(false);
  const [isCheckAll, setCheckAll] = React.useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [isCollapsed, setCollapse] = useState(false);

  useEffect(() => {
    const isEveryRouteActive = routes.every((item) => item.isActive);
    const isActive = routes.some((item) => item.isActive);

    if (!isActive) {
      setIndeterminate(false);
      setCheckAll(false);
    } else {
      setCheckAll(isEveryRouteActive);
      setIndeterminate(!isEveryRouteActive);
    }
  }, [routes]);

  const setListVisibility = () => setCollapse(!isCollapsed);

  const onCheckAllChange = (isChecked: boolean) =>
    setAllRouteVisibility(isChecked);

  const onChange = (isChecked: boolean, id: number) =>
    setRouteVisibility(id, isChecked);

  const renderRoutesConfiguration = (filteredRoutes: LayerItem[]) =>
    filteredRoutes.map((item) => (
      <Styled.RouteConfigurationContentContainer>
        <Styled.Checkbox
          onChange={({ target }) => onChange(target.checked, item.id)}
          checked={item.isActive}
        >
          <Styled.CheckboxContentContainer>
            <Styled.Line color={item.layer_color} />
            {item.common_name}
          </Styled.CheckboxContentContainer>
        </Styled.Checkbox>
      </Styled.RouteConfigurationContentContainer>
    ));

  const filteredRoutes: LayerItem[] = useMemo(
    () =>
      routes && routes.length
        ? routes
            .filter(({ layer_name }) => filterItem({ layer_name }, searchValue))
            .sort((a, b) => compare()(a.layer_name, b.layer_name))
        : [],
    [routes, searchValue]
  );

  return (
    <Styled.Container>
      <Styled.HeaderContainer>
        Widoczność tras
        <Styled.ToggleButton type={"ghost"} onClick={setListVisibility}>
          {isCollapsed ? <DownOutlined /> : <UpOutlined />}
        </Styled.ToggleButton>
      </Styled.HeaderContainer>
      <Styled.RoutesConfigurationContainer isCollapsed={isCollapsed}>
        <SearchInput
          prefix={<SearchOutlined />}
          placeholder="Szukaj..."
          onChange={(e) => setSearchValue(e.target.value)}
        />

        <Styled.Checkbox
          indeterminate={indeterminate}
          checked={isCheckAll}
          onChange={({ target }) => onCheckAllChange(target.checked)}
        >
          <Styled.CheckboxContentContainer>
            Zaznacz wszystkie
          </Styled.CheckboxContentContainer>
        </Styled.Checkbox>

        {renderRoutesConfiguration(filteredRoutes)}
      </Styled.RoutesConfigurationContainer>
    </Styled.Container>
  );
};

export default RoutesPicker;
