import { Overlay } from "ol";
import Feature from "ol/Feature";
import GeoJSON from "ol/format/GeoJSON";
import VectorSource from "ol/source/Vector";
import { Geometry } from "ol/geom";

import { queryFeatureStyle } from "./styles";

const DEFAULT_TOOLTIP_OFFSET = [0, -110];

const createFeature = (item: LayerItem) => {
  const { geometry, id, layer_color, layer_name, common_name } = item;

  const feature = new Feature({
    geometry: new GeoJSON().readGeometry(geometry),
  });

  feature.setId(id);
  const style = queryFeatureStyle(feature, geometry, layer_color);

  feature.setStyle(style.default);
  feature.setProperties({
    style,
    layer_name,
    tooltipContent: common_name ? common_name : id,
  });

  return feature;
};

const manageFeatures = (data: LayerItem[], source: VectorSource<Geometry>) =>
  data.map((item) => {
    const existingFeature = source
      .getFeatures()
      .find((feature) => feature.getId() === item.id);

    if (item.isActive) {
      if (!existingFeature) {
        const featureToAdd = createFeature(item);
        source.addFeature(featureToAdd);
      }
    } else {
      if (existingFeature) source.removeFeature(existingFeature);
    }
  });

const createTooltip = (container: HTMLElement | null) => {
  return new Overlay({
    element: container ? container : undefined,
    positioning: "top-center",
    offset: DEFAULT_TOOLTIP_OFFSET,
    stopEvent: true,
  });
};

export { manageFeatures, createFeature, createTooltip };
