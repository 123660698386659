import styled from "styled-components";
import trash_icon from "../../../assets/icons/trash_icon.svg";
import edit_icon from "../../../assets/icons/edit_icon.svg";

interface IProps {
  color: string;
}

export const LayerColor = styled.div<IProps>`
  width: 80px;
  height: 15px;
  border-radius: 3px;
  background-color: ${(props) => props.color};
`;

export const EditIcon = styled.div`
  background-image: url(${edit_icon});
  height: 19px;
  width: 19px;
`;

export const TrashIcon = styled.div`
  background-image: url(${trash_icon});
  height: 18px;
  width: 14px;
`;
