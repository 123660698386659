import { gql } from "@apollo/client";

const MEASUREMENT_SUBSCRIPTION = gql`
  subscription MeasurementSubscription {
    onCreateTracking {
      UUID_row
      gps_coordinates_X
      gps_coordinates_Y
      status
      time
      timetable
      packages
    }
  }
`;

export { MEASUREMENT_SUBSCRIPTION };
