import { gql } from "@apollo/client";

export const GET_CARS = gql`
  query QueryCars {
    listCars {
      id
      car_name
    }
  }
`;

export const UPDATE_CAR = gql`
  mutation UpdateCar($input: UpdateCarInput!) {
    updateCar(input: $input) {
      id
      car_name
    }
  }
`;

export const CREATE_CAR = gql`
  mutation CreateCar($input: CreateCarInput!) {
    createCar(input: $input) {
      car_name
      id
    }
  }
`;

export const DELETE_CAR = gql`
  mutation DeleteCar($input: DeleteCarInput!) {
    deleteCar(input: $input) {
      id
    }
  }
`;
