import React from "react";
import {
  Redirect,
  Route,
  Switch,
  Router as ReactRouter,
} from "react-router-dom";

import { history } from "../../helpers/History";
import * as Styled from "../../App.styles";

import { PrivateRoute } from "../common/PrivateRoute/PrivateRoute";

import Login from "../Login/Login";
import Dashboard from "../Dashboard/Dashboard";
import { AuthProvider } from "../../providers/AuthProvider";

export const Router = () => (
  <ReactRouter history={history}>
    <AuthProvider>
      <Styled.Container>
        <Switch>
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <Route path="/login" component={Login} />
          <Redirect to="/login" />
        </Switch>
      </Styled.Container>
    </AuthProvider>
  </ReactRouter>
);
