import React from "react";
import { Menu } from "antd";
import { NavLink, useLocation } from "react-router-dom";

import { LogoutOutlined, HomeOutlined } from "@ant-design/icons";

import * as Styled from "./Navbar.styles";
import { useAuth } from "../../../hooks/useAuth";

const Navbar = () => {
  const { pathname } = useLocation();
  const { signOut } = useAuth();

  return (
    <Styled.Container>
      <Styled.Menu mode="horizontal" selectedKeys={[pathname]}>
        <Menu.Item key={"/dashboard/map"}>
          <NavLink to="/dashboard/map">
            <HomeOutlined />
            <span>HOME</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key={"/dashboard/routes"}>
          <NavLink to="/dashboard/routes">
            <span>TRASY</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key={"/dashboard/drivers"}>
          <NavLink to="/dashboard/drivers">
            <span>KIEROWCY</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key={"/dashboard/operators-management"}>
          <NavLink to="/dashboard/operators-management">
            <span>OPERATORZY</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key={"/dashboard/cars"}>
          <NavLink to="/dashboard/cars">
            <span>SAMOCHODY</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key={"/dashboard/timetable"}>
          <NavLink to="/dashboard/timetable">
            <span>HARMONOGRAMY</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key={"/dashboard/measuring-packages"}>
          <NavLink to="/dashboard/measuring-packages">
            <span>PACZKI POMIAROWE</span>
          </NavLink>
        </Menu.Item>
      </Styled.Menu>

      <Styled.LogoutContainer data-testid="logout" onClick={signOut}>
        <Styled.LogoutLabel>Wyloguj</Styled.LogoutLabel>
        <LogoutOutlined />
      </Styled.LogoutContainer>
    </Styled.Container>
  );
};

export default Navbar;
