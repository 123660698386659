import React from "react";
import { Switch } from "react-router-dom";

import { PrivateRoute } from "../common/PrivateRoute/PrivateRoute";
import Navbar from "../common/Navbar/Navbar";

import RoutesView from "../Routes/RoutesView";
import DriversView from "../Drivers/DriversView";
import OperatorsManagement from "../OperatorsManagement/OperatorsManagement";
import MeasuringPackages from "../MeasuringPackages/MeasuringPackages";
import Home from "../Home/Home";
import TimetableManagementView from "../TimetableManagement/TimetableManagementView";
import CarsManagement from "../CarsManagement/CarsManagement";

import * as Styled from "./Dashboard.styles";
import { AppProviders } from "../../providers";
import { PhotosProvider } from "../../context/photosContext";
import { MeasuringPackagesProvider } from "../../context/measuringPackagesContext";

const Dashboard = () => {
  const renderHomeComponent = () => (
    <PhotosProvider>
      <Home />
    </PhotosProvider>
  );

  const renderMeasuringPackages = () => (
    <MeasuringPackagesProvider>
      <MeasuringPackages />
    </MeasuringPackagesProvider>
  );

  return (
    <Styled.Container>
      <AppProviders>
        <Navbar />

        <Switch>
          <PrivateRoute path="/dashboard/map" component={renderHomeComponent} />

          <PrivateRoute path="/dashboard/routes" component={RoutesView} />

          <PrivateRoute
            path="/dashboard/operators-management"
            component={OperatorsManagement}
          />
          <PrivateRoute path="/dashboard/drivers" component={DriversView} />

          <PrivateRoute
            path="/dashboard/measuring-packages"
            component={renderMeasuringPackages}
          />

          <PrivateRoute
            path="/dashboard/operators-management"
            component={OperatorsManagement}
          />

          <PrivateRoute
            path="/dashboard/timetable"
            component={TimetableManagementView}
          />

          <PrivateRoute path="/dashboard/cars" component={CarsManagement} />
        </Switch>
      </AppProviders>
    </Styled.Container>
  );
};

export default Dashboard;
